import {ComponentRef, Injectable, EventEmitter} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {map, merge, Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';

export interface OverlayComponent<InputType, OutputType = void> {
  close$: EventEmitter<OutputType>;
  receiveInput?: (input: InputType) => void;

  selfConfigure?: (overlay: OverlayRef) => void;
}

export interface AppDialog<OutputType = void> {
  overlayRef: OverlayRef;
  close: () => void;
  onClose$: Observable<OutputType | null>;
}


type OverlayInput<T> = T extends ComponentType<OverlayComponent<infer Generic, unknown>> ? Generic : unknown;
type OverlayOutput<T, I> = T extends ComponentType<OverlayComponent<I, infer O>> ? O : never

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private overlay: Overlay) { }

  openOverlay<T extends ComponentType<unknown>, I = OverlayInput<T>, O = OverlayOutput<T, I>>(component: T, componentInput: I): AppDialog<O> {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });
    const attachedComponent = overlayRef.attach(new ComponentPortal(component)) as ComponentRef<OverlayComponent<I, O>>;

    attachedComponent.instance.receiveInput?.(componentInput);
    attachedComponent.instance.selfConfigure?.(overlayRef);

    const onClose$ = new Subject<O | null>();
    const eventEmitterClose$ = attachedComponent.instance.close$.asObservable();
    const overlayClose$ = overlayRef.detachments().pipe(map(() => null));

    merge(eventEmitterClose$, overlayClose$)
      .pipe(take(1))
      .subscribe(result => {
        onClose$.next(result);
        overlayRef.dispose();
      });

    return { overlayRef, onClose$, close: () => overlayRef.dispose() };
  }
}
