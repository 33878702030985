import {Component, OnInit} from '@angular/core';
import {gql} from '../../../../gql';
import {ActivatedRoute} from '@angular/router';
import {catchError, filter, Subscription, switchMap} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {ErrorService} from '../../../shared/services/error.service';
import {QueryResult} from '../../../shared/utils';
import {Gender, Language} from '../../../../gql/graphql';

const NEWCOMER_QUERY = gql(/* GraphQL */`
  query NewcomerExportComponent_information($id: ID!) {
    newcomer(id: $id) {
      id
      firstName
      lastName
      dateOfBirth
      age
      parentFirstName
      parentLastName
      parentEmail
      parentPhone
      contactFirstName
      contactLastName
      contactEmail
      contactPhone
      street
      houseNumber
      zip
      city
      languages
      gender
    }
  }
`);

type NewcomerType = QueryResult<typeof NEWCOMER_QUERY>['newcomer'];

export const GENDER_OPTIONS: { [key in keyof typeof Gender]: { value: (typeof Gender)[key] | null, name: string } } = {
  MALE: {name: 'Jongen', value: Gender.MALE},
  FEMALE: {name: 'Meisje', value: Gender.FEMALE},
  OTHER: {name: 'Anders / onbekend', value: Gender.OTHER},
};

export const LANGUAGE_OPTIONS: {[key in keyof typeof Language]: { value: (typeof Language)[key] | null, name: string } } = {
  NL: {name: 'Nederlands', value: Language.NL},
  GB: {name: 'Engels', value: Language.GB},
  RU: {name: 'Russisch', value: Language.RU},
  UA: {name: 'Oekraïens', value: Language.UA},
  OTHER: {name: 'Anders', value: Language.OTHER}
};


@Component({
  selector: 'app-newcomer-export',
  templateUrl: './newcomer-export.component.html',
  styleUrls: ['./newcomer-export.component.scss']
})
export class NewcomerExportComponent implements OnInit {

  subscriptions = new Subscription();
  newcomer: NewcomerType[] = [];
  genderOptions = GENDER_OPTIONS;
  languageOptions = LANGUAGE_OPTIONS;
  gender: string | null = null;
  languages: string[] = [];

  constructor(private route: ActivatedRoute,
              private apollo: Apollo,
              private errorService: ErrorService) { }

  ngOnInit(): void {
    const newcomerExportSubscription = this.route.params.pipe(
      filter(params => params.hasOwnProperty('newcomerId')),
      switchMap((params) => {
        const newcomerId = params['newcomerId'];

        return this.apollo
          .watchQuery({query: NEWCOMER_QUERY, variables: {id: newcomerId}}).valueChanges
          .pipe(
            catchError(() => this.errorService.HandleGraphQLError('Ophalen van nieuwkomer'))
          );
      })
    ).subscribe(result => {
      if (result.data.newcomer) {
        this.newcomer = [{
          ...result.data.newcomer,
        }];
        if (result.data.newcomer.gender) {
          this.gender = this.genderOptions[result.data.newcomer.gender].name;
          result.data.newcomer.languages.forEach(language => {
            this.languages.push(this.languageOptions[language].name);
          });
        }
      }
    });

    this.subscriptions.add(newcomerExportSubscription);
  }

}
