import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {gql} from '../../../gql';
import {ActivatedRoute} from '@angular/router';
import {catchError, delay, EMPTY, filter, map, Observable, share, startWith, Subscription, switchMap} from 'rxjs';
import {hasNoEmptyAttributes, notNullOrUndefined, QueryResult, throwExpression} from '../../shared/utils';
import {NewcomerOverviewComponent} from '../newcomer-overview/newcomer-overview.component';
import {ErrorService} from '../../shared/services/error.service';
import {environment} from '../../../environments/environment';

const LOCATION_QUERY = gql(/* GraphQL */`
  query LocationComponent_information($id: ID!) {
    location(id: $id) {
      id
      name
      street
      houseNumber
      houseNumberAddition
      zipcode
      place
      latitude
      longitude
      newcomerToken
      totalCapacity
      remainingCapacity
    }
  }
`);

type LocationType = QueryResult<typeof LOCATION_QUERY>['location'] & {displayCapacity: string};

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss', '../DetailPageHeader.scss']
})
export class LocationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('newcomerOverviewComponent', {static: false}) newcomerOverviewComponent: NewcomerOverviewComponent | null = null;
  subscriptions = new Subscription();

  location: LocationType | null = null;
  location$: Observable<LocationType>;

  newcomerFilter: NewcomerOverviewComponent['location'] | null = null;

  mapCenter: google.maps.LatLngLiteral = {lat: 52.0705, lng: 4.3007};
  locationMarker: google.maps.LatLngLiteral | null = null;
  newcomerMarkers: {id: string, name: string, position: google.maps.LatLngLiteral}[] = [];

  newcomerMarkerIcon: google.maps.Icon = {
    url: '/assets/child-solid.svg',
    scaledSize: new google.maps.Size(30, 30),
    labelOrigin: new google.maps.Point(20, 40)
  };
  mapZoom: number = 10;

  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
    ],
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.location$ = this.activatedRoute.params
      .pipe(
        switchMap(params => {
          return this.apollo
            .watchQuery({query: LOCATION_QUERY, variables: {id: params['locatieId']}}).valueChanges
            .pipe(catchError(() => this.errorService.HandleGraphQLError('Ophalen van locatie')));
        }),
        map(queryResult => {
          const loc = queryResult.data.location;
          return {
            ...loc,
            displayCapacity: loc ? `${loc.remainingCapacity}/${loc.totalCapacity}` : '',
          } as LocationType;
        }),
        share()
      );
  }

  ngOnInit(): void {
    const routeLocationSubscription = this.location$.subscribe(location => {
        this.location = location;

        if (notNullOrUndefined(location)) {
          if (hasNoEmptyAttributes(location, ['latitude', 'longitude'])) {
            this.newcomerFilter = location;
            this.mapCenter = {lat: location.latitude, lng: location.longitude};
            this.locationMarker = {lat: location.latitude, lng: location.longitude};
          }
        }
      });

    this.subscriptions.add(routeLocationSubscription);
  }

  ngAfterViewInit() {
    const rangeSubscription = this.location$.pipe(
      delay(0),
      switchMap(() => this.mapZoom$())
    ).subscribe(zoom => this.mapZoom = zoom);

    this.subscriptions.add(rangeSubscription);

    const newcomerSubscription = this.location$.pipe(
      delay(0),
      switchMap(() => this.newcomerOverviewComponent?.records$ ?? throwExpression('Overviewcomponent missing'))
    ).subscribe(records => {
        this.newcomerMarkers = records.map(record => {
          return {
            id: record.id,
            name: `${record.firstName} ${record.lastName}`,
            position: {lat: record.latitude, lng: record.longitude} as google.maps.LatLngLiteral
          };
        });
      });

    this.subscriptions.add(newcomerSubscription);
  }

  mapZoom$(): Observable<number> {
    const overviewComponent = this.newcomerOverviewComponent ?? throwExpression('Overviewcomponent missing');

    function getBaseLog(x: number, y: number): number {
      return Math.log(y) / Math.log(x);
    }

    return overviewComponent.searchForm.controls.rangeFilter.controls.metersRange.valueChanges
      .pipe(
        startWith(overviewComponent.searchForm.value.rangeFilter?.metersRange ?? 3000),
        filter(notNullOrUndefined),
        map(metersRange => metersRange * 4),
        map(metersRange => getBaseLog(2, 40000 / (metersRange / 1000 / 2)))
      );
  }

  toCapacity() {
    window.open(environment.wegwijzer_url + '/location/po/' + this.location?.id + '/newcomers' , '_blank');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
