<div class="card overview-card">
  <div class="controls">
    <div class="control filling">
      <span class="p-input-icon-left w-100">
          <i class="pi pi-search"></i>
          <input class="p-inputtext w-100" type="text" pInputText [formControl]="filterForm.controls.search" placeholder="Zoeken">
<!--        p-inputtext p-component p-element w-100 ng-untouched ng-pristine ng-valid-->
      </span>
    </div>

    <div class="control">
      <p-dropdown [options]="tableFields" [formControl]="filterForm.controls.sortBy" optionLabel="description" optionValue="field" styleClass="w-100" placeholder="Sorteer op"></p-dropdown>
    </div>
  </div>

  <app-vtable #table [formHelperService]="formHelperService" [modelInfo]="models.Location" [fields]="tableFields" [filters]="filters" (onRowClick)="openLocation($event)"></app-vtable>
</div>
