import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Apollo, ResultOf} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, EMPTY, filter, map, Subscription, switchMap, withLatestFrom} from 'rxjs';
import {PLACEMENT_STATUS_OPTIONS} from '../newcomer-overview/newcomer-overview.component';
import {DateTime} from 'luxon';
import {gql} from '../../../gql';
import {QueryResult, throwExpression} from '../../shared/utils';
import {TabView} from 'primeng/tabview';
import {MenuItem} from 'primeng/api';
import {ErrorService} from '../../shared/services/error.service';

const NEWCOMER_QUERY = gql(/* GraphQL */`
  query NewcomerComponent_information($id: ID!) {
    newcomer(id: $id) {
      id

      firstName
      lastName

      placementStatus
      age
      poVo
      poGroup
      registrationDate
      longitude
      latitude
    }
  }
`);

type NewcomerType = QueryResult<typeof NEWCOMER_QUERY>['newcomer'] & {daysRegistered: number | null};

@Component({
  selector: 'app-newcomer',
  templateUrl: './newcomer.component.html',
  styleUrls: ['./newcomer.component.scss', '../DetailPageHeader.scss']
})
export class NewcomerComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  newcomer: NewcomerType | null = null;
  placementOptions = PLACEMENT_STATUS_OPTIONS;

  tabs: MenuItem[] | null = null;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    const newcomerSubscription = this.activatedRoute.params.pipe(
      filter(params => params.hasOwnProperty('newcomerId')),
      switchMap((params) => {
        const newcomerId = params['newcomerId'] as string;

        return this.apollo
          .watchQuery({query: NEWCOMER_QUERY, variables: {id: newcomerId}}).valueChanges
          .pipe(
            catchError(() => this.errorService.HandleGraphQLError('Ophalen van nieuwkomer'))
          );
      })
    ).subscribe(result => {
      if (result.data.newcomer) {
        this.newcomer = {
          ...result.data.newcomer,
          daysRegistered: Math.floor(-DateTime.fromISO(result.data.newcomer.registrationDate as string).diffNow('days').days)
        };

        this.tabs = [
          {label: 'Aanmelding', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'aanmelding']},
          {label: 'Gemeente', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'gemeente']},
          {label: 'Status / Plaatsing', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'plaatsingen']},
          {label: 'Scholen in de buurt', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'scholen']},
          {label: 'Export', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'export']},
          {label: 'Tijdlijn', routerLink: ['/', 'nieuwkomer', this.newcomer.id, 'tijdlijn']},
        ];
      }
    });

    this.subscriptions.add(newcomerSubscription);
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
