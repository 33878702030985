import {
  combineLatest, debounceTime,
  filter,
  from,
  map, merge,
  mergeMap,
  Observable, of,
  scan, share,
  shareReplay, startWith, switchMap, take
} from 'rxjs';
import {notNullOrUndefined} from './utils';
import {distinctUntilChanged} from 'rxjs/operators';

const PAGE_SIZE = 50;

export function infiniteScroller<FilterType, ResultType, PlaceholderType>(
  filters: FilterType,
  visibleArea$: Observable<{fromIndex: number, toIndex: number}>,
  getCount: (filters: FilterType) => Observable<number | null>,
  getPage: (amount: number, offset: number, filters: FilterType) => Observable<ResultType[]>,
  placeholder: PlaceholderType
): Observable<{ busy: boolean, recordCount: number | null, records: (ResultType | PlaceholderType)[] | null }>
{
  const count$ = getCount(filters).pipe(filter(notNullOrUndefined), share());

  const pageIndices$ = visibleArea$.pipe(
    map(visibleArea => {
      const fromPageIndex = Math.floor(visibleArea.fromIndex / PAGE_SIZE);
      const toPageIndex = Math.ceil(visibleArea.toIndex / PAGE_SIZE);

      // start, with one page before
      const startIndex = Math.max(fromPageIndex - 1, 0);

      // end, with one page after
      const endIndex = toPageIndex + 1;

      const pagesIndices: number[] = [];

      for (let index = startIndex; index < endIndex; index++) {
        pagesIndices.push(index);
      }

      return pagesIndices;
    }),
    debounceTime(50),
    distinctUntilChanged((a, b) => {
      return (a.length == b.length) && a.every((element, index) => element === b[index]);
    }),
    shareReplay({refCount: true, bufferSize: 1})
  );

  const indicesToFetch$ = count$.pipe(
    switchMap(() => pageIndices$),
    share()
  );

  const records$ = count$.pipe(
    map(count => new Array<ResultType | PlaceholderType>(count).fill(placeholder)),
    switchMap(records =>
      indicesToFetch$.pipe(
        mergeMap(pagesIndices => from(pagesIndices)),
        mergeMap(pageIndex => {
            if(records.length == 0) {
              return of([]);
            }

            return getPage(PAGE_SIZE, PAGE_SIZE * pageIndex, filters).pipe(
              map(pageResult => {
                for (let index = PAGE_SIZE * pageIndex, localIndex = 0; localIndex < PAGE_SIZE; index++, localIndex++) {
                  if (index >= records.length) {
                    break;
                  }
                  if (localIndex >= pageResult.length) {
                    break;
                  }
                  records[index] = pageResult[localIndex];
                }
                return [...records];
              })
            );
          }
        )
      )),
    shareReplay({refCount: true, bufferSize: 1})
  );

  const initialLoadingAction = of(1); // Getter of count is an action too.
  const loadingActions$ = merge(initialLoadingAction, indicesToFetch$.pipe(map((indices) => indices.length)));
  const doneActions$ = merge(count$, records$).pipe(map(() => -1));

  const loading$ = merge(loadingActions$, doneActions$).pipe(
    startWith(0),
    scan((total, value) => total + value, 0),
    map((active) => active > 0)
  );

  const recordsStartEmpty$ = merge(
    of(null),
    count$.pipe(
      take(1),
      map(count => new Array<ResultType | PlaceholderType>(count).fill(placeholder))
    ),
    records$
  );

  const countStartWithNull$ = count$.pipe(startWith(null));

  return combineLatest([loading$, countStartWithNull$, recordsStartEmpty$]).pipe(
    map(([busy, recordCount, records]) => ({busy, recordCount, records}))
  );
}
