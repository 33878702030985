<p-table [value]="newcomer">
  <ng-template pTemplate="body" let-newcomer>
    <tr>
      <td [style]="{'width': '300px'}">Registratienummer</td>
      <td>{{ newcomer.id }}</td>
    </tr>
    <tr>
      <td>Naam</td>
      <td>{{ newcomer.firstName }} {{ newcomer.lastName }}</td>
    </tr>
    <tr>
      <td>Geboorte datum</td>
      <td>{{ newcomer.dateOfBirth }}</td>
    </tr>
    <tr>
      <td>Leeftijd</td>
      <td>{{ newcomer.age }} jaar</td>
    </tr>
    <tr>
      <td>Naam ouder</td>
      <td>{{ newcomer.parentFirstName }} {{ newcomer.parentLastName }}</td>
    </tr>
    <tr>
      <td>E-mail ouder</td>
      <td>{{ newcomer.parentEmail }}</td>
    </tr>
    <tr>
      <td>Telefoonnummer ouder</td>
      <td>{{ newcomer.parentPhone }}</td>
    </tr>
    <tr>
      <td>Naam contactpersoon</td>
      <td>{{ newcomer.contactFirstName }} {{ newcomer.contactLastName }}</td>
    </tr>
    <tr>
      <td>E-mail contactpersoon</td>
      <td>{{ newcomer.contactEmail }}</td>
    </tr>
    <tr>
      <td>Telefoonnummer contactpersoon</td>
      <td>{{ newcomer.contactPhone }}</td>
    </tr>
    <tr>
      <td>Straat</td>
      <td>{{ newcomer.street }}</td>
    </tr>
    <tr>
      <td>Huisnummer</td>
      <td>{{ newcomer.houseNumber }}</td>
    </tr>
    <tr>
      <td>Postcode</td>
      <td>{{ newcomer.zip }}</td>
    </tr>
    <tr>
      <td>Woonplaats</td>
      <td>{{ newcomer.city }}</td>
    </tr>
    <tr>
      <td>Talen</td>
      <td>{{ this.languages }}</td>
    </tr>
    <tr>
      <td>Geslacht</td>
      <td>{{ this.gender }}</td>
    </tr>

  </ng-template>
</p-table>
