import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {notNull} from '../../../shared/utils';
import {ActivatedRoute} from '@angular/router';
import {catchError, merge, Subscription} from 'rxjs';
import {Language} from '../../../../gql/graphql';
import {ErrorService} from '../../../shared/services/error.service';
import {MessageService} from 'primeng/api';
import {gql} from '../../../../gql';
import {FormHelperService} from '../../../shared/services/form-helper.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {models} from '../../../shared/models';
import {FormSaveHelper} from '@vasio-nl/valow';
import {BooleanFieldTypeInfo, FieldInputType} from '@vasio-nl/valow';

const RIGHTS_QUERY = gql(/* GraphQL */`
 query GeneralNewcomerInformationComponent_rights {
   me {
     id
     managerHasAccessToSensitiveMessages
    }
   }
`);

@Component({
  selector: 'app-general-newcomer-information',
  templateUrl: './general-newcomer-information.component.html',
  styleUrls: ['./general-newcomer-information.component.scss']
})
export class GeneralNewcomerInformationComponent implements OnInit, OnDestroy {
  languageOptions = Language;
  subscriptions = new Subscription();
  saving = false;
  addressNotFound = false;
  userHasRightsToSensitiveData = false;

  public formContainer = this.formHelperService.getFormContainer(models.newComer, {
    id: true,
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    gender: true,
    poVo: true,
    poGroup: true,

    alreadyInNetherlands: true,
    arrivalDate: true,

    disabilitySupport: true,
    disabilitySupportExplanationNote: true,

    parentFirstName: true,
    parentLastName: true,
    parentPhone: true,
    parentEmail: true,

    contactFirstName: true,
    contactLastName: true,
    contactPhone: true,
    contactEmail: true,

    street: true,
    houseNumber: true,
    zip: true,
    city: true,

    willMoveSoon: true,
    newStreet: true,
    newHouseNumber: true,
    newZip: true,
    newCity: true,

    languages: true,
    otherLanguages: true,
    hasInterpreter: true,

    isUkrainian: true,

    remarks: true
  });
  public form = this.formContainer.form;

  public saveHelper = new FormSaveHelper(this.formContainer, this.messageService);
  destroyRef = inject(DestroyRef);

  constructor(private formHelperService: FormHelperService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private errorService: ErrorService,
              private apollo: Apollo) { }

  ngOnInit(): void {
    this.formHelperService.getData(this.formContainer.model, this.formContainer.fields, this.route.snapshot.params['newcomerId']).pipe(
      catchError(err => this.errorService.HandleGraphQLError('Laden van nieuwkomer gegevens')),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(data => {
      const preparedData = this.formHelperService.prepareDataForForm(this.formContainer.model, this.formContainer.fields, notNull(data.newcomer));
      this.formHelperService.updateFormFromModel(this.form, preparedData);
    });

    // savingSignal, saveFunction, response$
    // arguments: afterValidation, afterPrepare, handleTransimissionErrors, handleServerErrors, showSuccessMessage

    this.saveHelper.onSave$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.retrieveUserRights();

    const addressControls = [
      this.form.controls.street,
      this.form.controls.houseNumber,
      this.form.controls.zip,
      this.form.controls.city,
      this.form.controls.willMoveSoon,
      this.form.controls.newStreet,
      this.form.controls.newHouseNumber,
      this.form.controls.newZip,
      this.form.controls.newCity
    ];
    const addressChanges$ = merge(
      ...addressControls.map(control => control.valueChanges)
    );
    const addressChangeSubscription = addressChanges$.subscribe(output => {
      addressControls.forEach(control => control.setErrors(null));
    });

    this.subscriptions.add(addressChangeSubscription);
  }

  private retrieveUserRights() {
    const rightsSubscription = this.apollo.query({
      query: RIGHTS_QUERY
    }).pipe(
      catchError(() => this.errorService.HandleGraphQLError('Ophalen van gebruikersrechten'))
    ).subscribe(output => {
      this.userHasRightsToSensitiveData = output.data.me.managerHasAccessToSensitiveMessages;
    });

    this.subscriptions.add(rightsSubscription);
  }

  assertBooleanFieldType(fieldInfo: FieldInputType) {
    if (fieldInfo.type !== 'boolean') {
      throw new Error('Field is not a boolean field');
    }
    return fieldInfo as BooleanFieldTypeInfo;
  }

  speaksOtherLanguages(): boolean {
    return this.form.value.languages?.includes(this.languageOptions.OTHER) ?? false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
