import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {
  catchError,
  combineLatest,
  concat,
  debounceTime, map,
  mapTo, merge,
  Observable,
  OperatorFunction,
  startWith,
  take,
  tap, throwError,
  timer
} from 'rxjs';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {Table} from 'primeng/table';
import {ElementRef} from '@angular/core';
import {DateTime} from 'luxon';

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function notNull<TValue>(value: TValue | null | undefined): TValue {
  if (value === null || value === undefined) {
    throw new Error('Unexpected null');
  }
  return value;
}

export type QueryVariables<query> = query extends TypedDocumentNode<infer ResultType, infer VariablesType> ? VariablesType : never;
export type QueryResult<query> = query extends TypedDocumentNode<infer ResultType, infer VariablesType> ? ResultType : never;

type TypeToFormControl<T> =
  Exclude<T, null | undefined> extends Array<infer I> ? FormArray<TypeToFormControl<I>> :
    Exclude<T, null | undefined> extends Record<string, unknown> ? ModelToFormGroup<T> :
      Exclude<FormControl<T>, | null | undefined>;

export type ModelToFormGroup<M> = FormGroup<ModelToForm<M>>

// export type ModelToForm<M> = TypeToFormControl<M>;
export type ModelToForm<M> = { [key in keyof Required<Exclude<M, null>>]: TypeToFormControl<Required<Exclude<M, null>>[key]> }

export type SimpleModelToForm<M> = { [key in keyof Required<M>]: FormControl<Required<M>[key]> }

export type FormModelType<T extends FormGroup> = T['value'];

export function emptyFormArray<T extends AbstractControl>(): FormArray<T> {
  return new FormArray<T>([] as T[]);
}

export function debounceAllButFirst(dueTime: number) {
  return function <T>(source: Observable<T>): Observable<T> {
    return concat(
      source.pipe(take(1)),
      source.pipe(debounceTime(dueTime))
    );
  };
}

export function loadingIndicator<T>(show: () => void, hide: () => void): OperatorFunction<T, T> {
  const loadingShown$ = timer(300).pipe(
    tap(() => show()),
    mapTo(true),
    startWith(false)
  );

  return (input$) =>
    combineLatest([input$, loadingShown$]).pipe(
      take(1),
      catchError(err => {
        hide();
        return throwError(err);
      }),
      map(([input, delayShown]) => {
        if (delayShown) {
          hide();
        }

        return input;
      })
    );
}

export function getPTableVisibleRange$(table: Table) {
  return merge(table.scroller?.onScroll).pipe(
    startWith(null),
    map(() => table.scroller?.getRenderedRange()),
    map(scroll => ({fromIndex: scroll!.first, toIndex: scroll!.last}))
  );
}

// https://stackoverflow.com/a/65666402/14157414
export function throwExpression(errorMessage: string): never {
  throw new Error(errorMessage);
}

// useful for filtering with improved type output
type TypeWithRequiredKeys<T, K extends keyof T> = T & { [P in K]: Exclude<T[P], null | undefined> }
export function hasRequiredAttributes<T extends Record<string, unknown | null | undefined>, K extends keyof T>(requiredKeys: K[]) {
  return (input: T): input is TypeWithRequiredKeys<T, K> => {
    return requiredKeys.every(key => input[key] !== null && input[key] !== undefined);
  };
}

export function resizeObservable(element: ElementRef): Observable<ResizeObserverEntry[]> {
  return new Observable(subscriber => {
    const ro = new ResizeObserver(entries => {
      subscriber.next(entries);
    });

    ro.observe(element.nativeElement);
    return function unsubscribe() {
      ro.unobserve(element.nativeElement);
    };
  });
}

type TypeWithNonNullKeys<T, K extends keyof T> = T & { [P in K]: Exclude<T[P], null> }
export function hasNoEmptyAttributes<T extends Record<string, unknown | null>, K extends keyof T>(input: T, requiredKeys: K[]): input is T & Pick<Required<TypeWithNonNullKeys<T, K>>, K> {
  return requiredKeys.every(key => input[key] !== null && input[key] !== undefined);
}

export function datesToIso<T extends Record<string, unknown>, K extends keyof T>(input: T, dateKeys: K[]): T {
  const copy = {...input};

  dateKeys
    .filter(dateKey => notNullOrUndefined(input[dateKey]))
    .forEach(dateKey => copy[dateKey] = DateTime.fromJSDate(input[dateKey] as Date).toISODate() as T[K]);

  return copy;
}

export function dutchZipValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value === null || control.value === undefined) {
    return null;
  }

  const correctZip = /^\d{4}\s?[A-Z]{2}$/.test(control.value);
  return correctZip ? null : {incorrectZip: true};
}

export function triggerFormGroupValidation(formGroup: FormGroup): void {
  formGroup.markAllAsTouched();
  formGroup.updateValueAndValidity();

  Object.keys(formGroup.controls).forEach(key => {
    const subControl = (formGroup.controls[key] as AbstractControl);


    (formGroup.controls[key] as AbstractControl).updateValueAndValidity({onlySelf: false});
    subControl.markAllAsTouched();

    // eslint-disable-next-line no-prototype-builtins
    if (subControl.hasOwnProperty('controls')) {
      if (Array.isArray((subControl as any)['controls'])) {
        triggerFormArrayValidation(subControl as FormArray);
      } else {
        triggerFormGroupValidation(subControl as FormGroup);
      }
    }
  });
}

export function triggerFormArrayValidation(formArray: FormArray) {
  formArray.controls.forEach(control => {
    control.markAllAsTouched();
    control.updateValueAndValidity();

    // eslint-disable-next-line no-prototype-builtins
    if (control.hasOwnProperty('controls')) {
      if (Array.isArray((control as any)['controls'])) {
        triggerFormArrayValidation(control as FormArray);
      } else {
        triggerFormGroupValidation(control as FormGroup);
      }
    }
  });
}

export type TableColumn<T> = {
  field: (keyof T) | null,
  key: string,
  name: string,
  width?: string
};

export function graphqlUnionTypeGuard<Type extends {__typename: unknown}, Key extends keyof Type, Value extends string & Type[Key]>(typeName: Value) {
  return (o: Type): o is Extract<Type, Record<Key, Value>> => {
    return o['__typename'] === typeName;
  };
}

export function oneRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ('controls' in control && !Array.isArray((control as FormGroup | FormArray).controls)) {
      const formGroup = control as FormGroup;

      const oneControlHasValue = Object.keys(formGroup.controls).some(key => formGroup.controls[key].value !== null);
      if (oneControlHasValue) {
        return null;
      }

      return {message: 'Minstens één van de velden moet ingevult zijn.'};
    }

    return null;
  };
}
