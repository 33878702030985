

  <app-loading-bar [active]="loadingRecords"></app-loading-bar>

  <p-table #table
           class="virtual-scrolling-table"
           [resizableColumns]="true"
           [columns]="columns"
           [scrollable]="true"
           [virtualScroll]="true"
           [virtualScrollItemSize]="50"
           [autoLayout]="false"
           scrollHeight="flex">
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="columns.length + 1">
          Er zijn geen locaties die voldoen aan de criteria.
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          {{col.name}}
        </th>
        <th style="width: 40px"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-location>
      <tr *ngIf="assertRecordType(location)" (click)="navigate(location)">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns" [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'distance'">
            <ng-container>{{ location.distance | number: '1.0-1' }}km</ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="col.field !== null">{{location[col.field]}}</ng-container>
          </ng-container>
        </td>
        <td style="width: 40px">
          <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/', 'locatie', location.id]"></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
