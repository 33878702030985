<header class="accent-color">
  <div class="container left-and-right text-white">
    <a [routerLink]="['/']" class="center-vertically" id="title">Nieuwkomers</a>

    <div class="center-vertically">
      <a pButton pRipple [href]="wegwijzerPath" type="button" label="Wegwijzer" class="p-button-text p-button-plain p-button-white button-row-margin"></a>

      <span class="center-vertically menu-trigger" *ngIf="me" (click)="menu.toggle($event)">
        {{ me.name }}
      </span>
      <p-slideMenu #menu [model]="meMenuItems" [popup]="true" [viewportHeight]="90" [style]="{'width':'190px'}"></p-slideMenu>
    </div>
  </div>
</header>

<div class="container">
  <nav class="card">
    <a #newcomerOverviewButton
       pButton pRipple
       class="p-button-text" [class.p-button-secondary]="!newcomerOverviewButton.classList.contains('p-button-primary')"
       [routerLink]="['/', 'nieuwkomers-overzicht']" routerLinkActive="p-button-primary">
      Nieuwkomers
    </a>

    <a #locationsButton
       pButton pRipple
       class="p-button-text" [class.p-button-secondary]="!locationsButton.classList.contains('p-button-primary')"
       [routerLink]="['/', 'locaties-overzicht']" routerLinkActive="p-button-primary">
      Locaties
    </a>

    <a #usersButton
       pButton pRipple
       class="p-button-text" [class.p-button-secondary]="!usersButton.classList.contains('p-button-primary')"
       [routerLink]="['/', 'users-overzicht']" routerLinkActive="p-button-primary">
      Users
    </a>

    <a #placementOfferButton
       pButton pRipple
       class="p-button-text" [class.p-button-secondary]="!placementOfferButton.classList.contains('p-button-primary')"
       [routerLink]="['/', 'plaatsing-voorstellen-overzicht']" routerLinkActive="p-button-primary">
      Plaatsingsvoorstellen
    </a>

    <a #reportsButton
       pButton pRipple
       class="p-button-text" [class.p-button-secondary]="!reportsButton.classList.contains('p-button-primary')"
       [routerLink]="['/', 'reports']" routerLinkActive="p-button-primary">
      Rapportages
    </a>

  </nav>
</div>

<div id="main-content" class="container">
  <router-outlet></router-outlet>
</div>
