<div class="card overview-card">
  <div class="controls">
    <div class="control filling">
      <span class="p-input-icon-left w-100">
          <i class="pi pi-search" *ngIf="!loadingRecordCount"></i>
          <i class="pi pi-spin pi-spinner" *ngIf="loadingRecordCount"></i>
          <input class="p-inputtext w-100" type="text" pInputText [formControl]="searchForm.controls.search" placeholder="Zoeken">
      </span>
    </div>

    <div class="control">
      <a pButton pRipple class="p-button-success" [routerLink]="['/user']">Nieuw</a>
    </div>
    <div class="control">
        <div class="p-element record-count">({{users.length}})</div>
    </div>
  </div>

  <app-loading-bar [active]="loadingRecords"></app-loading-bar>

  <p-table #table
           class="virtual-scrolling-table"
           [resizableColumns]="true"
           [columns]="columns"
           [scrollable]="true"
           [value]="users"
           [virtualScroll]="false"
           [autoLayout]="false"
           scrollHeight="flex">
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="columns.length + 1">
          Er zijn geen users die voldoen aan de criteria.
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          {{col.name}}
        </th>
        <th style="width: 40px"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user>
      <tr *ngIf="user.loading === true">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          <p-skeleton width="100%"></p-skeleton>
        </td>
        <td style="width: 40px">
          <p-skeleton width="100%"></p-skeleton>
        </td>
      </tr>

      <tr *ngIf="assertUserType(user) as u" (click)="navigate(u)">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns" [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'locations'">
            <ng-container *ngFor="let loc of u.newcomerLocations; let last = last;">{{loc.name}}<ng-container *ngIf="!last">, </ng-container></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="col.field !== null">{{user[col.field]}}</ng-container>
          </ng-container>
        </td>
        <td style="width: 40px">
          <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/', 'user', user.id]"></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
