import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {catchError, EMPTY, Observable} from 'rxjs';
import {OAuthModuleConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {AuthenticationService} from './shared/services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {

  constructor(
    private oAuth: OAuthService,
    private authenticationService: AuthenticationService,
    @Optional() private moduleConfig: OAuthModuleConfig,
    private router: Router
  ) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {

    if (this.oAuth.hasValidAccessToken()) {
      if (req.url === environment.graphql_endpoint) {
        const modifiedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${this.oAuth.getAccessToken()}`),
        });

        return next.handle(modifiedReq)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status === 403) {
                this.router.navigate(['/', 'geen-toegang']).then();
                return EMPTY;
              }

              if (err.status === 401) {
                console.error('Login expired');
                this.authenticationService.login();
                return EMPTY;
              } else {
                throw err;
              }
            })
          )
      }
    } else {
      this.authenticationService.login();
    }

    return next.handle(req);
  }

}
