import {Component, Input} from '@angular/core';
import {distinctUntilChanged, mapTo, switchMap} from 'rxjs/operators';
import {BehaviorSubject, iif, Observable, of, tap, timer} from 'rxjs';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent {
  @Input() set active(active: boolean) {
    this.active$.next(active);
  }

  active$ = new BehaviorSubject<boolean>(false);

  showing$: Observable<boolean>;

  constructor() {
    this.showing$ = this.active$
      .pipe(
        distinctUntilChanged(),
        switchMap(active => iif(() => active, timer(300).pipe(mapTo(true)), of(false)))
      );
  }

}
