<div class="card">
  <h1>U heeft geen toegang.</h1>
  <p>
    Welkom in de nieuwkomers beheer applicatie. U heeft niet de juiste rechten om deze applicatie te gebruiken.
    Neem contact op met uw contactpersoon om toegang tot deze omgeving aan te vragen.
  </p>
  <p>
    Wanneer u toegang heeft gekregen kan het tot 5 minuten duren voordat u de applicatie kan gebruiken.
  </p>
</div>
