<p-toast></p-toast>

<form cdkOverlayOrigin #formOverlayOrigin="cdkOverlayOrigin" class="floating-save-form">
  <h2>Gegevens kind</h2>
  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['firstName']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['lastName']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['dateOfBirth']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['gender']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['alreadyInNetherlands']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['arrivalDate']"></app-form-field>
    </div>
  </div>

  <div class="form-row" *ngIf="userHasRightsToSensitiveData">
    <div class="field w-100 flex-item">
      <app-form-field [input]="formContainer.componentInput['disabilitySupport']"></app-form-field>
    </div>

    <div class="field w-100 flex-item">
      <app-form-field [input]="formContainer.componentInput['disabilitySupportExplanationNote']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['poVo']"></app-form-field>
    </div>

    <div class="field w-100">
      <!--      <ng-container *ngIf="form.controls.poGroup.enabled">-->
      <app-form-field [input]="formContainer.componentInput['poGroup']"></app-form-field>
      <!--      </ng-container>-->
    </div>
  </div>


  <h2>Gegevens Ouder</h2>
  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['parentFirstName']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['parentLastName']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['parentEmail']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['parentPhone']"></app-form-field>
    </div>
  </div>

  <h2>Gegevens Contactpersoon</h2>
  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['contactFirstName']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['contactLastName']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['contactEmail']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['contactPhone']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['street']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['houseNumber']"></app-form-field>
    </div>
  </div>

  <div class="form-row">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['zip']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['city']"></app-form-field>
    </div>
  </div>

  <app-boolean-checkbox class="boolean-checkbox" [control]="formContainer.form.controls.willMoveSoon" [fieldInfo]="assertBooleanFieldType(formContainer.componentInput['willMoveSoon'].fieldInfo)" [showLabel]="true"></app-boolean-checkbox>

  <ng-container *ngIf="form.controls.willMoveSoon.value === true">
    <div class="form-row will-move-soon-row">
      <div class="field w-100">
        <app-form-field [input]="formContainer.componentInput['newStreet']"></app-form-field>
      </div>

      <div class="field w-100">
        <app-form-field [input]="formContainer.componentInput['newHouseNumber']"></app-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="field w-100">
        <app-form-field [input]="formContainer.componentInput['newZip']"></app-form-field>
      </div>

      <div class="field w-100">
        <app-form-field [input]="formContainer.componentInput['newCity']"></app-form-field>
      </div>
    </div>
  </ng-container>

  <h2>Gesproken talen</h2>
  <app-form-field [input]="formContainer.componentInput['languages']"></app-form-field>

  <ng-container *ngIf="speaksOtherLanguages()">
    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['otherLanguages']"></app-form-field>
    </div>
  </ng-container>

  <div class="form-row">

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['hasInterpreter']"></app-form-field>
    </div>

    <div class="field w-100">
      <app-form-field [input]="formContainer.componentInput['isUkrainian']"></app-form-field>
    </div>
  </div>

  <div class="field w-100" *ngIf="userHasRightsToSensitiveData">
    <app-form-field [input]="formContainer.componentInput['remarks']"></app-form-field>
  </div>

  * Verplichte velden
</form>

<app-form-footer [formOverlayOrigin]="formOverlayOrigin">
  <p-button class="save-button" label="Opslaan" icon="pi pi-save" [loading]="saveHelper.saving()" (onClick)="saveHelper.save()">
  </p-button>
</app-form-footer>
