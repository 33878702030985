import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {BehaviorSubject, startWith, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {throwExpression} from '../../shared/utils';
import {environment} from '../../../environments/environment';


const ERROR_MESSAGES: {[error: string]: (val: any) => string} = {
  required: () => `This field is required.`,
  min: (val: {actual: number, min: number}) => `Value must be at least` + ` ${val.min}` + '.',
  email: () => `This is not a correct email address.`,
  message: (val) => `${val}`,
  datetime: (val) => `The end time cannot be earlier than the start time.`,
  matDatetimePickerParse: (val) => `The date is invalid.`,
  matDatepickerParse: (val) => `The date is invalid.`,
  noTopLevelDomain: () => `The email address should contain a dot (.).`,
  inactiveUser: () => `Inactive user`
};

@Component({
  selector: 'app-form-field-errors',
  templateUrl: './form-field-errors.component.html',
  styleUrls: ['./form-field-errors.component.scss']
})
export class FormFieldErrorsComponent implements OnInit {
  @Input() control!: AbstractControl<any>;
  errors$ = new BehaviorSubject<string[]>([]);

  constructor() { }

  ngOnInit(): void {
    if (this.control === null) {
      throw new Error('Form field error display not attached to control.');
    }

    this.control.statusChanges.subscribe(_ => this.getErrors());
    this.getErrors();
  }

  getErrors(): void {
    if (!this.control.errors) {
      this.errors$.next([]);
      return;
    }

    const errorKeys = Object.keys(this.control.errors);

    const errors = errorKeys.map(key => {
      if (key in ERROR_MESSAGES) {
        return ERROR_MESSAGES[key](this.control.errors![key]);
      }

      if(!environment.production) {
        console.error(`No error message for validation "${key}"`);
        console.error(this.control.errors![key]);
      }

      return `This field contains an error.`;
    });

    this.errors$.next(errors);
  }
}
