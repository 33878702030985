import { Injectable } from '@angular/core';
import {models} from "../models";

import {
  generateMutationOp,
  generateQueryOp,
  Mutation,
  Query,
  QueryGenqlSelection
} from "../../../genql";
import {FormHelperBaseService} from '@vasio-nl/valow';

type AppGenQL = {
    generateMutationOp: typeof generateMutationOp,
    generateQueryOp: typeof generateQueryOp,
    Mutation: Mutation,
    Query: Query,
    QueryGenqlSelection: QueryGenqlSelection
}

@Injectable({
  providedIn: 'root'
})
export class FormHelperService extends FormHelperBaseService<typeof models, AppGenQL> {
  override models = models;
  override genql = {
    generateMutationOp,
    generateQueryOp,
  };
}
