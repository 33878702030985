<div class="card">
  <ng-container *ngIf="errors?.length === 1">
    <h3>Er is een probleem opgetreden.</h3>
    <p>{{errors?.[0]?.problem}}: {{errors?.[0]?.message}}</p>
  </ng-container>

  <ng-container *ngIf="errors !== null && errors.length > 1">
    <h3>Er zijn meerdere problemen opgetreden.</h3>
    <ul>
      <li *ngFor="let error of errors">
        {{error.problem}}: {{error.message}}
      </li>
    </ul>
  </ng-container>

  <p>Neem contact op met uw contactpersoon als dit probleem vaker voorkomt.</p>

  <p-button (click)="close$.emit()" styleClass="p-button-sm">Sluiten</p-button>
  <p-button (click)="reload()" styleClass="p-button-sm p-button-warning" >Pagina verversen</p-button>
</div>


