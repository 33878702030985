import {Component, OnInit, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {FormControl, FormGroup} from '@angular/forms';
import {notNull, throwExpression} from '../../shared/utils';
import {PoVo} from '../../../gql/graphql';
import {Router} from '@angular/router';
import {ErrorService} from '../../shared/services/error.service';
import {AdditionalRouteEvents} from '../../app-route-reuse-strategy';
import { models } from 'src/app/shared/models';
import {FormHelperService} from "../../shared/services/form-helper.service";
import {VTableComponent} from '@vasio-nl/valow';

export const CATEGORY_OPTIONS: { [key in keyof typeof PoVo | 'all']: { value: (typeof PoVo & { all: null })[key], name: string } } = {
  all: {name: "PO en VO", value: null},
  PO: {name: "Primair onderwijs", value: PoVo.PO},
  VO: {name: "Voortgezet onderwijs", value: PoVo.VO},
};


@Component({
  selector: 'app-location-overview',
  templateUrl: './location-overview.component.html',
  styleUrls: ['./location-overview.component.scss']
})
export class LocationOverviewComponent implements OnInit, AdditionalRouteEvents {
  @ViewChild('table', {static: true}) table: VTableComponent<any, any, any> | null = null;
  models = models;
  tableFields = this.formHelperService.getTableFields('Location', ["capacityDisplay", "cityAreaName", "name", "capacityUpdatedAt", "oldestPlacementOffer", "lastReactionOnPlacementOffer"]);
  filters = {isNewcomer: true, search: '', orderBy: 'capacityUpdatedAt'};

  filterForm = new FormGroup({
    search: new FormControl<null | string>(null),
    sortBy: new FormControl<string>('capacityUpdatedAt')
  });

  constructor(
    private apollo: Apollo,
    private router: Router,
    private errorService: ErrorService,
    public formHelperService: FormHelperService
  ) { }

  ngOnInit(): void {
    const table = this.table ?? throwExpression('Table did not initialize');

    this.filterForm.controls.search.valueChanges.subscribe(search => {
      this.filters = {
        ...this.filters,
        search: search ?? ''
      };
    })

    this.filterForm.controls.sortBy.valueChanges.subscribe(sortBy => {
      this.filters = {
        ...this.filters,
        orderBy: sortBy ?? 'name'
      };
    });
  }

  willEnter(): void {
    setTimeout(() => {
      notNull(this.table).scrollTop();
    });
  }

  openLocation(location: any) {
    this.router.navigate(['/', 'locatie', location.id]).then();
  }
}
