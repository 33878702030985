<p-toast></p-toast>

<div class="note-wrapper">
  <h2>Voeg een notitie toe</h2>

  <app-form-field [input]="formContainer.componentInput['text']"></app-form-field>
  <div class="button-wrapper">
    <p-button class="save-button" label="Opslaan" icon="pi pi-save" [loading]="saveHelper.saving()" (onClick)="saveHelper.save()"></p-button>
  </div>
</div>


<div class="timeline-wrapper">
  <h2>Tijdlijn</h2>

  <p-table [value]="timelineItems">
    <ng-template pTemplate="body" let-item>
      <ng-container *ngIf="assertTimelineType(item) as item">
        <tr>
          <td style="width: 250px" *ngIf="item.__typename === 'Note'">Notitie</td>
          <td style="width: 250px" *ngIf="item.__typename === 'NewComerStatusHistory'">Status</td>
          <td style="width: 250px" *ngIf="item.__typename === 'PlacementOffer'">Plaatsingvoorstel</td>
          <td style="width: 150px">{{ item.createdAt | date: 'd-MM-YYYY' }}</td>
          <td *ngIf="item.__typename === 'Note' && !userHasRightsToSensitiveData">Geen rechten om notitie in te zien</td>
          <td *ngIf="item.__typename === 'Note' && userHasRightsToSensitiveData">{{ item.text }}</td>
          <td *ngIf="item.__typename === 'NewComerStatusHistory'">
            <p-tag *ngIf="item.oldStatus"
                   [value]="placementOptions[item.oldStatus].name"
                   [severity]="placementOptions[item.oldStatus].color"
                   class="sub-header-item">
            </p-tag>
            <span class="arrow">-></span>
            <p-tag *ngIf="item.status"
                   [value]="placementOptions[item.status].name"
                   [severity]="placementOptions[item.status].color"
                   class="sub-header-item">
            </p-tag>
          </td>
          <td *ngIf="item.__typename === 'PlacementOffer'">
            <ng-container *ngIf="item.location">{{ item.location.name }}</ng-container>
          </td>
        </tr>
      </ng-container>


    </ng-template>
  </p-table>
</div>
