
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "LocationType": [
      "StringLocationType",
      "WegwijzerLocationType"
    ],
    "Node": [
      "NewComer",
      "WegwijzerLocationType"
    ],
    "timelineItem": [
      "NewComerStatusHistory",
      "Note",
      "PlacementOffer"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "LocationType": [
      "StringLocationType",
      "WegwijzerLocationType"
    ],
    "Node": [
      "NewComer",
      "WegwijzerLocationType"
    ],
    "timelineItem": [
      "NewComerStatusHistory",
      "Note",
      "PlacementOffer"
    ]
  }
};
      export default result;
    