<div class="card">
  <div class="table-header" *ngIf="placementOfferId">
    <h1>Plaatsingvoorstel #{{ placementOfferId }}</h1>
  </div>

  <div class="table-body" *ngIf="assertItemType(records[0]) as offer">

      <p-table [value]="records">
        <ng-template pTemplate="body">
          <tr>
            <td>Naam</td>
            <td>{{ offer.newcomer.firstName }}  {{ offer.newcomer.lastName }} <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/', 'nieuwkomer', offer.newcomer.id]"></a></td>
          </tr>
          <tr>
            <td>Locatie</td>
            <td *ngIf="offer.location && offer.location.__typename === 'WegwijzerLocationType'">{{ offer.location.name }} <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/locatie/', offer.location.id]"></a></td>
          </tr>
          <tr *ngIf="offer.capacity">
            <td>Leeftijd</td>
            <td *ngIf="offer.capacity.age && offer.capacity.age.length > 0">{{ offer.capacity.age }} jaar oud</td>
            <td *ngIf="offer.capacity.age  && offer.capacity.age.length === 0">Geen leeftijd gespecificeerd</td>
          </tr>
          <tr *ngIf="offer.capacity">
            <td>Beschrijving</td>
            <td *ngIf="offer.capacity.description">{{ offer.capacity.description }}</td>
          </tr>
          <tr>
            <td>Datum voorgesteld</td>
            <td>{{ offer.date | date: 'dd-MM-yyyy' }}</td>
          </tr>
          <tr>
            <td>Datum reactie</td>
            <td *ngIf="offer.resolutionAt">{{ offer.resolutionAt | date: 'dd-MM-yyyy' }}</td>
            <td *ngIf="!offer.resolutionAt">Nog niet behandeld</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <p-tag *ngIf="offer.resolution"
                     styleClass="wrap"
                     [value]="placementOfferOptions[offer.resolution].name"
                     [severity]="placementOfferOptions[offer.resolution].color">
              </p-tag>
            </td>
          </tr>
          <tr>
            <td>Gekeurd door</td>
            <td *ngIf="offer.resolutionBy">{{ offer.resolutionBy }}</td>
            <td *ngIf="!offer.resolutionBy">Nog niet behandeld</td>
          </tr>
          <tr *ngIf="offer.resolution === PlacementOfferResolution.REJECTED || offer.resolution === PlacementOfferResolution.CANCELLED && userHasRightsToSensitiveData">
            <td>Reden voor afwijzing</td>
            <td *ngIf="offer.failureReason" [innerHTML]="offer.failureReason | lineBreaks"></td>
          </tr>
          <tr *ngIf="offer.finalized">
            <td>Verwerkt door CAPN</td>
            <td>{{ offer.finalizedAt | date: 'dd-MM-yyyy' }}</td>
          </tr>

        </ng-template>
      </p-table>


      <div class="form-wrapper" *ngIf="offer && !offer.finalized || cancelPlacementOffer">
        <span>Nieuwkomer status</span>
        <p-dropdown [options]="placementOptionsArray" [formControl]="newcomerStatusControl"  optionLabel="name" optionValue="value" styleClass="w-100" placeholder="Selecteer status"></p-dropdown>
        <span *ngIf="cancelPlacementOffer">Reden van annulering</span>
        <textarea pInputTextarea [formControl]="cancelMessage" *ngIf="cancelPlacementOffer"></textarea>
        <button (click)="submitPlacementOffer(offer)" class="p-ripple p-element p-button p-component save-button" [disabled]="newcomerStatusControl.value === null">Opslaan</button>
      </div>

    <button *ngIf="offer.resolution !== PlacementOfferResolution.CANCELLED && !cancelPlacementOffer" class="p-ripple p-element p-button p-button-danger p-component cancel-button" (click)="cancel()">Annuleren</button>

  </div>
</div>
