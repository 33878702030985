import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {CdkOverlayOrigin, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {throwExpression} from '../../shared/utils';
import {delay, map, startWith, Subscription, tap} from 'rxjs';
import {ViewportRuler} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss']
})
export class FormFooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formOverlayOrigin: CdkOverlayOrigin | null = null;
  @ViewChild('portalContent', {static: true}) contentTemplate: TemplateRef<unknown> | null = null;

  overlayRef: OverlayRef | null = null;

  subscriptions = new Subscription();

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private viewportRuler: ViewportRuler,
  ) { }

  ngOnInit(): void {
    const formElement = this.formOverlayOrigin ?? throwExpression('FormElement must be passed');
    const contentTemplate = this.contentTemplate ?? throwExpression('ContentTemplate must be passed');

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(formElement.elementRef)
        .withPush(true)
        .withPositions([{originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'bottom'}]),
      panelClass: 'white-overlay-background',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    const contentPortal = new TemplatePortal(contentTemplate, this.viewContainerRef);
    this.overlayRef.attach(contentPortal);

    const widthSubscription = this.viewportRuler.change(50).pipe(
      delay(0),
      startWith(0),
      map(() => formElement.elementRef.nativeElement.getBoundingClientRect().width ?? 0),
      map(pixels => `${pixels}px`)
    ).subscribe(width => this.overlayRef?.updateSize({width}));

    this.subscriptions.add(widthSubscription);
  }

  public updatePosition() {
    setTimeout(() => this.overlayRef?.updatePosition(), 0);
  }

  ngAfterViewInit() {
    this.updatePosition();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
