<p-toast></p-toast>

<div class="card overview-card" *ngIf="form.enabled">
  <app-form-field [input]="formContainer.componentInput['newcomer']"></app-form-field>
  <app-form-field [input]="formContainer.componentInput['location']"></app-form-field>

  <div class="capacity-form-item" *ngIf="form.controls.location.value">
    <div class="capacity-container">
      <div class="capacity-item" *ngFor="let capacity of form.controls.location.value.capacity">
        <label class="click-label" [for]="'capacityInput-' + capacity.id"></label>
        <div class="label-wrapper">
          <label *ngIf="capacity.description" class="description">{{ capacity.description }}</label>
          <label *ngIf="capacity.age && capacity.age.length > 0" class="group-name">{{ capacity.age }} jaar oud</label>
          <label *ngIf="capacity.age && capacity.age.length === 0" class="group-name">Geen leeftijd gespecificeerd</label>
          <label class="capacity-info">{{ capacity.remainingCapacity }} / {{ capacity.capacity }} plekken beschikbaar</label>
        </div>
        <div class="input-wrapper">
          <input type="radio" [value]="capacity" [formControl]="form.controls.capacity" [id]="'capacityInput-' + capacity.id">
        </div>
      </div>
    </div>
    <app-form-field-errors [control]="form.controls.capacity"></app-form-field-errors>
  </div>

  <p-button class="save-button" label="Opslaan" icon="pi pi-save" [loading]="saveHelper.saving()" (click)="saveHelper.save()">
  </p-button>
</div>
