<div class="card">

  <ng-container *ngIf="newcomer; else placeholder">
    <h1 *ngIf="newcomer">{{newcomer.firstName}} {{newcomer.lastName}}</h1>

    <div class="sub-header">
      <div class="sub-header-item">
        #{{ newcomer.id }}
      </div>

      <p-tag *ngIf="newcomer.placementStatus"
             [value]="placementOptions[newcomer.placementStatus].name"
             [severity]="placementOptions[newcomer.placementStatus].color"
             class="sub-header-item">
      </p-tag>

      <span class="sub-header-item">{{newcomer.age}} jaar</span>

      <div class="sub-header-item">
        {{newcomer.poVo}}
        <span *ngIf="newcomer.poVo === 'PO'">(groep {{newcomer.poGroup}})</span>
      </div>

      <div class="sub-header-item">
        {{newcomer.daysRegistered}} dagen geregistreerd
      </div>

      <p-tag *ngIf="newcomer.longitude === null || newcomer.latitude === null"
             [value]="'onbekend adres'"
             [severity]="'warning'"
             class="sub-header-item">
      </p-tag>
    </div>
  </ng-container>

  <ng-template #placeholder>
    <h1>
      <p-skeleton width="400px" height="2em"></p-skeleton>
    </h1>

    <div class="sub-header">
      <p-skeleton class="sub-header-item" width="100px" height="26px"></p-skeleton>

      <p-skeleton class="sub-header-item" width="50px" height="14px"></p-skeleton>

      <p-skeleton class="sub-header-item" width="25px" height="14px"></p-skeleton>

      <p-skeleton class="sub-header-item" width="100px" height="14px"></p-skeleton>
    </div>
  </ng-template>
</div>

<div class="card">
  <ng-container *ngIf="tabs !== null">
    <p-tabMenu [model]="tabs" [scrollable]="true"></p-tabMenu>
    <div class="tab-container">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</div>
