import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CallbackComponent} from './pages/callback/callback.component';
import {NewcomerOverviewComponent} from './pages/newcomer-overview/newcomer-overview.component';
import {NewcomerComponent} from './pages/newcomer/newcomer.component';
import {LocationOverviewComponent} from './pages/location-overview/location-overview.component';
import {LocationComponent} from './pages/location/location.component';
import {
  GeneralNewcomerInformationComponent
} from './pages/newcomer/general-newcomer-information/general-newcomer-information.component';
import {
  TownshipNewcomerInformationComponent
} from './pages/newcomer/township-newcomer-information/township-newcomer-information.component';
import {
  NewcomerPlacementInformationComponent
} from './pages/newcomer/newcomer-placement-information/newcomer-placement-information.component';
import {NoAccessComponent} from './pages/no-access/no-access.component';
import {NewcomerExportComponent} from './pages/newcomer/newcomer-export/newcomer-export.component';
import {ReportsComponent} from "./pages/reports/reports.component";
import {PlacementOfferOverviewComponent} from './pages/placement-offer-overview/placement-offer-overview.component';
import {PlacementOfferComponent} from './pages/placement-offer/placement-offer.component';
import {UsersOverviewComponent} from "./pages/users-overview/users-overview.component";
import {NewcomerTimelineComponent} from './pages/newcomer/newcomer-timeline/newcomer-timeline.component';
import {NearbyLocationsComponent} from './pages/newcomer/nearby-locations/nearby-locations.component';
import {NewPlacementOfferComponent} from "./pages/new-placement-offer/new-placement-offer.component";
import {UserComponent} from "./pages/user/user.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'nieuwkomers-overzicht',
  },
  {
    path: 'nieuwkomers-overzicht',
    component: NewcomerOverviewComponent
  },
  {
    path: 'nieuwkomer/:newcomerId',
    component: NewcomerComponent,
    children: [
      {path: '', redirectTo: 'aanmelding', pathMatch: 'full'},
      {path: 'aanmelding', component: GeneralNewcomerInformationComponent},
      {path: 'gemeente', component: TownshipNewcomerInformationComponent},
      {path: 'plaatsingen', component: NewcomerPlacementInformationComponent},
      {path: 'scholen', component: NearbyLocationsComponent},
      {path: 'export', component: NewcomerExportComponent},
      {path: 'tijdlijn', component: NewcomerTimelineComponent}
    ]
  },
  {
    path: 'nieuwkomer/:newcomerId/:tab',
    component: NewcomerComponent
  },
  {
    path: 'locaties-overzicht',
    component: LocationOverviewComponent
  },
  {
    path: 'users-overzicht',
    component: UsersOverviewComponent
  },
  {
    path: 'user',
    component: UserComponent
  },
  {
    path: 'user/:id',
    component: UserComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'locatie/:locatieId',
    component: LocationComponent
  },
  {
    path: 'plaatsing-voorstellen-overzicht',
    component: PlacementOfferOverviewComponent
  },
  {
    path: 'nieuw-plaatsing-voorstel',
    component: NewPlacementOfferComponent
  },
  {
    path: 'plaatsing-voorstel/:placementOfferId',
    component: PlacementOfferComponent
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'geen-toegang',
    component: NoAccessComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
