<p-toast></p-toast>
<h2>CDW</h2>
<app-form-field [input]="formContainer.componentInput['CDW']"></app-form-field>

<h2>Informatie vanuit LBA</h2>
<app-form-field [input]="formContainer.componentInput['LBAArrivalDate']"></app-form-field>
<app-form-field [input]="formContainer.componentInput['LBACurrentResidence']"></app-form-field>
<app-form-field [input]="formContainer.componentInput['LBASchool']"></app-form-field>
<app-form-field [input]="formContainer.componentInput['LBASchoolPhone']"></app-form-field>
<app-form-field [input]="formContainer.componentInput['LBASchoolTownship']"></app-form-field>

<p-button class="save-button" label="Opslaan" icon="pi pi-save"  (click)="saveHelper.save()">
</p-button>

