import { Injectable } from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';

export const OAUTH_CONFIG: AuthConfig = {
  issuer: environment.oauth_endpoint,
  loginUrl: `${environment.oauth_endpoint}/oauth/authorize/`,
  logoutUrl: environment.oauth_endpoint + '/logout',
  redirectUri: window.location.origin + "/callback",
  tokenEndpoint: `${environment.oauth_endpoint}/oauth/token/`,
  clientId: environment.oauth_client_id,
  responseType: 'code',
  requireHttps: environment.production,
  scope: 'read',
  showDebugInformation: true,
  oidc: false,
  useIdTokenHintForSilentRefresh: false,
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private oAuthService: OAuthService
  ) {
  }

  public initializeOAuth(): void {
    this.oAuthService.setupAutomaticSilentRefresh(undefined, 'access_token');
    this.oAuthService.initLoginFlow();
    this.oAuthService.configure(OAUTH_CONFIG);
    this.oAuthService.tryLogin({});
  }

  public login(): void {
    if (location.pathname !== '/callback') {
      localStorage.setItem('redirect_location', (location.pathname + location.search));
      localStorage.setItem('redirect_location_time', '' + new Date().getTime() / 1000);
    }

    this.oAuthService.initLoginFlow();
  }

  public getToken(): string | null {
    return this.oAuthService.getAccessToken();
  }

}
