<div class="card">

  <ng-container *ngIf="location; else placeholder">
    <h1 *ngIf="location">{{location.name}}</h1>

    <div class="sub-header">
      <a class="sub-header-item clickable" [routerLink]="['/', 'nieuw-plaatsing-voorstel']" [queryParams]="{'location_id': location.id}">
        {{ location.displayCapacity }} plekken beschikbaar
      </a>
      <span>|</span>
      <span class="sub-header-item">
        {{location.street}}
        {{location.houseNumber}}{{location.houseNumberAddition}},
        {{location.zipcode}} {{location.place}}
      </span>
    </div>
  </ng-container>

  <ng-template #placeholder>
    <h1>
      <p-skeleton width="400px" height="2em"></p-skeleton>
    </h1>

    <div class="sub-header">
      <p-skeleton class="sub-header-item" width="30px" height="26px"></p-skeleton>
      <p-skeleton class="sub-header-item" width="300px" height="14px"></p-skeleton>
    </div>
  </ng-template>
</div>

<div class="card no-padding contain">
  <google-map [center]="mapCenter" [zoom]="mapZoom" [options]="mapOptions" width="100%" height="300px">
    <map-marker *ngIf="locationMarker !== null" [position]="locationMarker"></map-marker>
    <map-marker *ngFor="let newcomerMarker of newcomerMarkers"
                [position]="newcomerMarker.position"
                [icon]="newcomerMarkerIcon"
                [label]="newcomerMarker.name">
    </map-marker>
  </google-map>
</div>

<app-newcomer-overview #newcomerOverviewComponent *ngIf="newcomerFilter !== null" [oldFirst]="true" [location]="newcomerFilter"></app-newcomer-overview>
