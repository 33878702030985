import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {BehaviorSubject, catchError, debounceTime, map, pairwise, startWith, Subscription, switchMap} from 'rxjs';
import {AreaCategory, Area, PlacementStatus, PoVo} from '../../../gql/graphql';
import {FormControl, FormGroup} from '@angular/forms';
import {
    getPTableVisibleRange$,
    hasNoEmptyAttributes,
    ModelToForm, notNull,
    notNullOrUndefined,
    QueryResult,
    QueryVariables,
    TableColumn,
    throwExpression
} from '../../shared/utils';
import {infiniteScroller} from '../../shared/infiniteScrolling';
import {Table} from 'primeng/table';
import {Router} from '@angular/router';
import {gql} from '../../../gql';
import {ErrorService} from '../../shared/services/error.service';
import { AdditionalRouteEvents } from 'src/app/app-route-reuse-strategy';
import {distinctUntilChanged} from 'rxjs/operators';


type RecordType = QueryResult<typeof NEWCOMER_PAGE_QUERY>['newcomers']['edges'][number]["node"];

const NEWCOMERS_COUNT_QUERY = gql(/* GraphQL */`
    query OverviewComponent_newcomerCount(
        $search: String
        $placementStatus: [PlacementStatus!]
        $rangeFilter: RangeFilter
        $areaId: ID
        $speaksUkrainian: Boolean,
        $shouldHaveBeenPlaced: Boolean
    ) {
        newcomers(
            search: $search
            placementStatus__in: $placementStatus
            rangeFilter: $rangeFilter
            areaId: $areaId
            speaksUkrainian: $speaksUkrainian,
            shouldHaveBeenPlaced: $shouldHaveBeenPlaced
        ) {
            totalCount
        }
    }
`);

const NEWCOMER_PAGE_QUERY = gql(/* GraphQL */`
    query OverviewComponent_newcomerPage(
        $search: String
        $placementStatus: [PlacementStatus!]
        $rangeFilter: RangeFilter
        $areaId: ID
        $speaksUkrainian: Boolean
        $after: String
        $take: Int
        $oldFirst: Boolean
        $shouldHaveBeenPlaced: Boolean
    ) {
        newcomers(
            search: $search
            placementStatus__in: $placementStatus
            rangeFilter: $rangeFilter
            areaId: $areaId
            speaksUkrainian: $speaksUkrainian
            oldFirst: $oldFirst
            after: $after,
            first: $take,
            shouldHaveBeenPlaced: $shouldHaveBeenPlaced
        ) {
            edges {
                node {
                    __typename
                    id
                    CDW
                    firstName
                    lastName
                    gender
                    placementStatus
                    registrationDate
                    latitude
                    longitude
                    age
                    poGroup
                    arrivalDate
                }
            }
        }
    }
`);

const SHOULD_HAVE_BEEN_PLACED_QUERY = gql(/* GraphQL */`
    query OverviewComponent_shouldHaveBeenPlaced {
        newcomers(shouldHaveBeenPlaced: true) {
            totalCount
        }
    }
`);

const AREAS = gql(/* GraphQL */`
    query OverviewComponent_areas {
        areas {
            id
            name
            category
        }
    }
`);

const COLUMNS: TableColumn<RecordType>[] = [
    {
        field: 'id',
        key: 'id',
        name: '#',
        width: '100px'
    },
    {
        field: 'placementStatus',
        key: 'placementStatus',
        name: 'Status',
        width: '180px'
    },
    {
        field: null,
        key: 'newcomer',
        name: 'Nieuwkomer',
        // width: '50px !important'
    },
    {
        field: 'age',
        key: 'age',
        name: 'Leeftijd',
        width: '100px'
    },
    {
        field: 'arrivalDate',
        key: 'arrivalDate',
        name: 'Aankomstdatum',
        width: '150px'
    },
    {
        field: 'registrationDate',
        key: 'registrationDate',
        name: 'Aanmelddatum',
        width: '150px'
    }
];

type severityColors = 'success' | 'info' | 'warning' | 'danger';

export const PLACEMENT_STATUS_OPTIONS: { [key in keyof typeof PlacementStatus]: { value: (typeof PlacementStatus & { all: null })[key] | null, name: string, color: severityColors | undefined } } = {
    NEW: {name: 'Nieuw', value: PlacementStatus.NEW, color: 'info'},
    SIGNUP_PO: {name: 'Aanmelden PO', value: PlacementStatus.SIGNUP_PO, color: 'warning'},
    DOUBLE_REGISTRATION: {name: 'Dubbel', value: PlacementStatus.DOUBLE_REGISTRATION, color: 'warning'},
    PLACED: {name: 'Geplaatst Nieuwkomersgroep (regulier)', value: PlacementStatus.PLACED, color: 'success'},
    PLACED_ARRIVAL_LOCATION: {name: 'Geplaatst Nieuwkomersgroep (noodplan)', value: PlacementStatus.PLACED_ARRIVAL_LOCATION, color: 'success'},
    PLACED_TEMPORARY_LOCATION: {name: 'Geplaatst Nieuwkomersgroep (TOV)', value: PlacementStatus.PLACED_TEMPORARY_LOCATION, color: 'success'},
    COMPULSORY_EDUCATION: {name: 'Leerplicht', value: PlacementStatus.COMPULSORY_EDUCATION, color: 'warning'},
    YET_TO_ARRIVE: {name: 'Nog niet in Den haag', value: PlacementStatus.YET_TO_ARRIVE, color: 'warning'},
    RESERVED: {name: 'Reservering Nieuwkomersgroep (regulier)', value: PlacementStatus.RESERVED, color: undefined},
    LEFT: {name: 'Vertrokken uit gemeente', value: PlacementStatus.LEFT, color: 'warning'},
    WAITING_LIST: {name: 'Op wachtlijst', value: PlacementStatus.WAITING_LIST, color: 'info'},
    WAITING_LIST_ARRIVAL_LOCATION: {name: 'Reservering Nieuwkomersgroep (noodplan)', value: PlacementStatus.WAITING_LIST_ARRIVAL_LOCATION, color: undefined},
    WAITING_LIST_TEMPORARY_LOCATION: {name: 'Reservering Nieuwkomersgroep (TOV)', value: PlacementStatus.WAITING_LIST_TEMPORARY_LOCATION, color: undefined},
    WAITING_LIST_CARE: {name: 'Op wachtlijst (zorg)', value: PlacementStatus.WAITING_LIST_CARE, color: 'warning'},
    IMPROVEMENT_NEEDED: {name: 'Plaatsverbetering', value: PlacementStatus.IMPROVEMENT_NEEDED, color: 'warning'},
    OUTSIDE_THE_HAGUE: {name: 'Buiten Den Haag', value: PlacementStatus.OUTSIDE_THE_HAGUE, color: undefined},
    TODDLER: {name: 'Kleuter', value: PlacementStatus.TODDLER, color: undefined},
    TODDLER_WAITING_LIST: {name: 'Wachtlijst Kleuter', value: PlacementStatus.TODDLER_WAITING_LIST, color: 'warning'},
    REFUSSAL: {name: "Plek geweigerd", value: PlacementStatus.REFUSSAL, color: 'warning'},
    SIGNUP_VO: {name: "Aanmelden VO", value: PlacementStatus.SIGNUP_VO, color: 'warning'},
    NO_CONTACT: {name: "Geen contact", value: PlacementStatus.NO_CONTACT, color: 'warning'},
    ALREADY_FOUND_A_SCHOOL: {name: "Reeds een school gevonden", value: PlacementStatus.ALREADY_FOUND_A_SCHOOL, color: 'warning'},
    EXEMPT_FROM_EDUCATION: {name: "Vrijgesteld van onderwijs", value: PlacementStatus.EXEMPT_FROM_EDUCATION, color: 'warning'},
};

const SPEAKS_UKRAINIAN_OPTIONS: {[key in 'yes' | 'no' | 'either']: {name: string, value: boolean | null}} = {
    either: {name: 'Alle talen', value: null},
    yes: {name: 'Oekraïens', value: true},
    no: {name: 'Geen Oekraïens', value: false}
};

const PO_VO_OPTIONS: {[key in PoVo.PO | PoVo.VO | 'either']: {name: string, value: key | null}} = {
    either: {name: 'PO/VO', value: null},
    [PoVo.PO]: {name: 'PO', value: PoVo.PO},
    [PoVo.VO]: {name: 'VO', value: PoVo.VO}
};

@Component({
    selector: 'app-newcomer-overview',
    templateUrl: './newcomer-overview.component.html',
    styleUrls: ['./newcomer-overview.component.scss']
})
export class NewcomerOverviewComponent implements AfterViewInit, OnDestroy, AdditionalRouteEvents {
    @ViewChild('table', {static: true}) table: Table | null = null;

    @Input() set location(value: {latitude: number, longitude: number}) {
        const {latitude, longitude} = value;
        this.searchForm.patchValue({rangeFilter: {latitude, longitude}});
        this.rangeFilterActive = true;
        this.searchForm.controls.rangeFilter.controls.metersRange.setValue(3000);
    }

    @Input() oldFirst = false;
    subscriptions = new Subscription();

    columns = COLUMNS;
    placementOptions = PLACEMENT_STATUS_OPTIONS;
    placementOptionsArray = Object.values(PLACEMENT_STATUS_OPTIONS);
    ukrainianOptionsArray = Object.values(SPEAKS_UKRAINIAN_OPTIONS);
    PoVoOptionsArray = Object.values(PO_VO_OPTIONS);
    recordCount: number = 0;
    showShouldHaveBeenPlacedWarning = false;

    distanceOptionsArray: {name: string, value: number}[] = [
        {name: '15KM', value: 15000},
        {name: '10KM', value: 10000},
        {name: '5KM', value: 5000},
        {name: '3KM', value: 3000},
        {name: '2KM', value: 2000},
        {name: '1KM', value: 1000},
        {name: '500M', value: 500},
    ];

    searchForm = new FormGroup<ModelToForm<QueryVariables<typeof NEWCOMERS_COUNT_QUERY>>>({
        search: new FormControl(null),
        placementStatus: new FormControl([]),
        rangeFilter: new FormGroup({
            metersRange: new FormControl(),
            latitude: new FormControl(),
            longitude: new FormControl(),
        }),
        areaId: new FormControl(null),
        speaksUkrainian: new FormControl(null),
        shouldHaveBeenPlaced: new FormControl(false),

    });


    loadingRecords = false;
    loadingRecordCount = false;
    records$ = new BehaviorSubject<RecordType[]>([]);

    rangeFilterActive = false;
    areas: Area[] = [];

    constructor(
        private apollo: Apollo,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private errorService: ErrorService
    ) {
    }

    getInfiniteScroller(filters: QueryVariables<typeof NEWCOMERS_COUNT_QUERY>) {
        const table = this.table ?? throwExpression('Table did not initialize');

        const getCount = () => {
            return this.apollo
                .watchQuery({query: NEWCOMERS_COUNT_QUERY, variables: filters}).valueChanges
                .pipe(
                    catchError(() => this.errorService.HandleGraphQLError('Ophalen van hoeveelheid nieuwkomers')),
                    map(queryResult => queryResult.data.newcomers.totalCount)
                );
        };

        const getRecords = (amount: number, offset: number) => {
            return this.apollo
                .watchQuery({query: NEWCOMER_PAGE_QUERY, variables: {...filters, after: offset ? btoa(`OffsetConnection:${offset}`) : undefined, take: amount, oldFirst: this.oldFirst}}).valueChanges
                .pipe(
                    catchError(() => this.errorService.HandleGraphQLError('Ophalen van nieuwkomers lijst')),
                    map(queryResult => queryResult.data.newcomers.edges.map(e => e.node))
                );
        };

        return infiniteScroller(filters, getPTableVisibleRange$(table), getCount, getRecords, { __typename: 'LoadingRecordType' as const, loading: true } as {__typename: 'LoadingRecordType', loading: boolean});
    }

    ngAfterViewInit(): void {
        const table = this.table ?? throwExpression('Table did not initialize');

        const formChangeSubscription = this.searchForm.valueChanges.pipe(
          pairwise()
        ).subscribe(([oldValue, newValue]) => {
            table.scroller?.scrollTo({top: 0});
            this.loadingRecordCount = true;
            if (oldValue.shouldHaveBeenPlaced && newValue.shouldHaveBeenPlaced) {
                this.searchForm.controls.shouldHaveBeenPlaced.setValue(false);
            }
        });

        const tableSubscription = this.searchForm.valueChanges.pipe(
            debounceTime(500),
            startWith(this.searchForm.value),
            map(filters => {
                let rangeFilter = null;

                if (notNullOrUndefined(filters.rangeFilter)) {
                    if (hasNoEmptyAttributes(filters.rangeFilter, ['latitude', 'longitude'])) {
                        rangeFilter = filters.rangeFilter;
                    }
                }

                return {
                    ...filters,
                    rangeFilter: rangeFilter
                };
            }),
            switchMap(filters => this.getInfiniteScroller(filters))
        ).subscribe((result) => {
            const {busy, recordCount, records} = result;

            if (recordCount !== null) {
                this.recordCount = recordCount;
            }

            this.loadingRecords = busy;
            this.loadingRecordCount = recordCount === null;

            if (records !== null) {
                table.value = records;

                this.records$.next(records.filter(record => record.__typename === 'NewComer') as Extract<(typeof records)[0], {__typename: 'NewComer'}>[]);
            }

            this.cdr.detectChanges();
        });

        this.subscriptions.add(
            this.apollo.query({
                query: SHOULD_HAVE_BEEN_PLACED_QUERY,
            }).pipe(
                catchError(() => this.errorService.HandleGraphQLError('Ophalen van hoeveelheid nieuwkomers die geplaatst hadden moeten zijn')),
            ).subscribe(output => {
                if (output.data.newcomers.totalCount > 0) {
                    this.showShouldHaveBeenPlacedWarning = true;
                }
            })
        );


        this.subscriptions.add(formChangeSubscription);
        this.subscriptions.add(tableSubscription);


        this.subscriptions.add(
            this.apollo.watchQuery({
                query: AREAS,
            }).valueChanges.subscribe(output => {
                this.areas = output.data.areas;
            })
        );
    }

    assertNewcomerType(newcomer: unknown): newcomer is RecordType {
        if (typeof newcomer === 'object' && newcomer != null) {
            return !('loading' in newcomer);
        }

        return false;
    }

    navigate(newcomer: RecordType): void {
        this.router.navigate(['/', 'nieuwkomer', newcomer.id]).then();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    willEnter(): void {
        setTimeout(() => {
            notNull(this.table).scroller?.scrollTo({top: 1});
        });
    }

    categoryToDisplay(category: AreaCategory) {
        switch (category) {
            case AreaCategory.WIJK:
                return 'Wijk';
            case AreaCategory.BUURT:
                return 'Buurt';
            case AreaCategory.STADSDEEL:
                return 'Stadsdeel';
        }
    }
}
