<p-toast></p-toast>

<form class="floating-save-form">

  <app-form-field [input]="formContainer.componentInput['centralSignupRemarks']"></app-form-field>

  <h2>Status</h2>
  <app-form-field [input]="formContainer.componentInput['placementStatus']"></app-form-field>
  <app-form-field [input]="formContainer.componentInput['schoolPlacement']"></app-form-field>

  <h2>
    <span>Aangeboden locaties</span>
    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-primary p-button-text" (click)="newPlacementOffer()"></button>
  </h2>
  <p-table #table
           class="virtual-scrolling-table"
           [resizableColumns]="true"
           [columns]="columns"
           [scrollable]="true"
           [virtualScroll]="true"
           [virtualScrollItemSize]="50"
           [autoLayout]="false"
           scrollHeight="flex">

    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          {{col.name}}
        </th>
        <th style="width: 40px"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="columns.length + 1">
          Er zijn nog geen locaties aangeboden.
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-placementOffer>
      <tr *ngIf="assertItemType(placementOffer) as offer" (click)="navigate(offer)" [class.needs-finalized]="offer.needsFinalized">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns" [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'date'">
            <ng-container>{{ offer.date | date: 'd-MM-YYYY' }}</ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'resolution'">
            <p-tag *ngIf="offer.resolution"
                   styleClass="wrap"
                   [value]="placementOfferOptions[offer.resolution].name"
                   [severity]="placementOfferOptions[offer.resolution].color">
            </p-tag>
          </ng-container>
          <ng-container *ngSwitchCase="'resolutionAt'">
            <ng-container>{{ offer.resolutionAt | date: 'dd-MM-YYYY' }}</ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'location'">
            <ng-container>{{ offer.location }}</ng-container>
          </ng-container>
        </td>
        <td style="width: 40px">
          <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/', 'plaatsing-voorstel', offer.id]"></a>
        </td>
      </tr>
    </ng-template>

  </p-table>

</form>

<p-button class="save-button" label="Opslaan" icon="pi pi-save" [loading]="saveHelper.saving()" (onClick)="saveHelper.save()"></p-button>
