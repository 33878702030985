<div class="card overview-card">
  <div class="controls">
    <div class="control filling">
      <span class="p-input-icon-left w-100">
          <i class="pi pi-search" *ngIf="!loadingRecordCount"></i>
          <i class="pi pi-spin pi-spinner" *ngIf="loadingRecordCount"></i>
          <input class="p-inputtext w-100" type="text" pInputText [formControl]="searchForm.controls.search" placeholder="Zoeken">
      </span>
    </div>
    <div class="control multiselect-control">
      <p-multiSelect [options]="placementOptionsArray" [formControl]="searchForm.controls.placementStatus"  optionLabel="name" optionValue="value" styleClass="w-100" placeholder="Selecteer status"></p-multiSelect>
    </div>

    <div class="control range-control" *ngIf="rangeFilterActive">
      <p-dropdown [options]="distanceOptionsArray" [formControl]="searchForm.controls.rangeFilter.controls.metersRange" optionLabel="name" optionValue="value" styleClass="w-100"></p-dropdown>
    </div>

    <div class="control">
      <p-dropdown [options]="ukrainianOptionsArray" [formControl]="searchForm.controls.speaksUkrainian" placeholder="Spreekt Oekraïens" optionLabel="name" optionValue="value" styleClass="w-100"></p-dropdown>
    </div>

    <div class="control">
      <p-dropdown [options]="areas" [showClear]="true" [formControl]="searchForm.controls.areaId" [filter]="true" placeholder="Locatie" optionLabel="name" optionValue="id" styleClass="w-100">
        <ng-template let-area pTemplate="item">
          <div class="country-item">
            <div>{{area.name}}</div>
            <div style="font-size: 13px; color: #333;">{{categoryToDisplay(area.category)}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="p-element record-count">({{ recordCount}})</div>
  </div>

  <p class="error" *ngIf="showShouldHaveBeenPlacedWarning && !searchForm.controls.shouldHaveBeenPlaced.value" (click)="searchForm.controls.shouldHaveBeenPlaced.setValue(true)">Er zijn een of meerdere nieuwkomers die na 3 maanden nog niet zijn geplaatst. Klik op deze melding om ze te bekijken.</p>

  <app-loading-bar [active]="loadingRecords"></app-loading-bar>

  <p-table #table
           class="virtual-scrolling-table"
           [resizableColumns]="true"
           [columns]="columns"
           [scrollable]="true"
           [virtualScroll]="true"
           [virtualScrollItemSize]="50"
           [autoLayout]="false"
           scrollHeight="flex">
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="columns.length + 1">
          Er zijn geen nieuwkomers die voldoen aan de criteria.
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          {{col.name}}
        </th>
        <th style="width: 40px"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-newcomer>
      <tr *ngIf="newcomer.loading === true">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns">
          <p-skeleton width="100%"></p-skeleton>
        </td>
        <td style="width: 40px">
          <p-skeleton width="100%"></p-skeleton>
        </td>
      </tr>

      <tr *ngIf="assertNewcomerType(newcomer)" (click)="navigate(newcomer)">
        <td [ngStyle]="{width: col.width, 'max-width': col.width}" *ngFor="let col of columns" [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'placementStatus'">
            <p-tag *ngIf="newcomer.placementStatus"
                   styleClass="wrap"
                   [value]="placementOptions[newcomer.placementStatus].name"
                   [severity]="placementOptions[newcomer.placementStatus].color">
            </p-tag>
          </ng-container>

          <ng-container *ngSwitchCase="'newcomer'">
            {{newcomer.firstName}} {{newcomer.lastName}}
            <ng-container [ngSwitch]="newcomer.gender">
              <span *ngSwitchCase="'MALE'" title="Jongen">♂</span>
              <span *ngSwitchCase="'FEMALE'" title="Meisje">♀</span>
            </ng-container>
            <ng-container *ngIf="newcomer.longitude === null || newcomer.latitude === null">
              <span class="p-tag p-tag-warning address-warning-tag">onbekend adres</span>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'age'">
            <span [title]="newcomer.age">
              {{ newcomer.age }} jaar
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'arrivalDate'">
            <span [title]="newcomer.arrivalDate | date:'full'">
              {{newcomer.arrivalDate | date:'dd-MM-YYYY'}}
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'registrationDate'">
            <span [title]="newcomer.registrationDate | date:'full'">
              {{newcomer.registrationDate | date:'dd-MM-YYYY'}}
            </span>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="col.field !== null">{{newcomer[col.field]}}</ng-container>
          </ng-container>
        </td>
        <td style="width: 40px">
          <a pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded p-button-text" [routerLink]="['/', 'nieuwkomer', newcomer.id]"></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
