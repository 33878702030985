import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { notNull } from './shared/utils';

function doesRouteMatch(activatedRoute: ActivatedRouteSnapshot, path: string, parentPath?: string): boolean {
  if (parentPath) {
    return activatedRoute.routeConfig?.path === path && activatedRoute.parent?.routeConfig?.path === parentPath;
  }
  return activatedRoute.routeConfig?.path === path;
}

function findMatchingRoute(activatedRoute: ActivatedRouteSnapshot, cacheableRoutes: CacheableRoute[]): CacheableRoute | null {
  return cacheableRoutes.find(route => doesRouteMatch(activatedRoute, route.path, route.parentPath)) ?? null;
}

function shouldBeCached(activatedRoute: ActivatedRouteSnapshot, cacheableRoutes: CacheableRoute[]): boolean {
  return findMatchingRoute(activatedRoute, cacheableRoutes) !== null;
}

function findCachedHandle(routeName: CacheableRouteName, handles: RouteHandle[]): RouteHandle | null {
  return handles.find(handle => handle.name === routeName) || null;
}

function hasCachedHandle(routeName: CacheableRouteName, handles: RouteHandle[]): boolean {
  return findCachedHandle(routeName, handles) !== null;
}

export const cachedRoutes = [
  '/nieuwkomers-overzicht',
  '/locaties-overzicht',
  '/locatie/:locatieId'
] as const;

export type CacheableRouteName = typeof cachedRoutes[number];

const routeDefinitions: { [key in CacheableRouteName]: Omit<CacheableRoute, 'name'> & {name: key} } = {
  '/nieuwkomers-overzicht': { path: 'nieuwkomers-overzicht', name: '/nieuwkomers-overzicht' },
  '/locaties-overzicht': { path: 'locaties-overzicht', name: '/locaties-overzicht' },
  '/locatie/:locatieId': { path: 'locatie/:locatieId', name: '/locatie/:locatieId' }
};

const cacheableRoutes = Object.values(routeDefinitions);

interface RouteHandle {
  name: string;
  handle: DetachedRouteHandle;
}

interface CacheableRoute {
  path: string;
  parentPath?: string;
  name: CacheableRouteName;
}

export abstract class AdditionalRouteEvents {
  abstract willEnter: () => void;
}

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy implements RouteReuseStrategy {
  handles: RouteHandle[] = [];

  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return shouldBeCached(route, cacheableRoutes);
  }

  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (handle === null) {
      return;
    }
    const matchingRoute = findMatchingRoute(route, cacheableRoutes);

    if (matchingRoute !== null) {
      this.handles.push({
        name: matchingRoute.name,
        handle
      });
    }
  }

  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!shouldBeCached(route, cacheableRoutes)) {
      return false;
    }

    const cacheableRoute = findMatchingRoute(route, cacheableRoutes);
    return hasCachedHandle(notNull(cacheableRoute).name, this.handles);
  }

  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const cacheableRoute = findMatchingRoute(route, cacheableRoutes);
    const cachedHandle = findCachedHandle(notNull(cacheableRoute).name, this.handles);

    if (cachedHandle === undefined || cachedHandle === null) {
      return null; // Should not occur. Also, returning null apparently produces an error.
    }

    let instance = (cachedHandle.handle as any).componentRef.instance;
    if(typeof instance.willEnter === 'function') {
      instance.willEnter();
    }

    return cachedHandle.handle;
  }

}
