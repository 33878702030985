import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {BehaviorSubject, catchError, debounceTime, startWith, Subscription, switchMap} from 'rxjs';
import {Area} from '../../../gql/graphql';
import {FormControl, FormGroup} from '@angular/forms';
import {
  ModelToForm, notNull,
  QueryResult,
  TableColumn,
} from '../../shared/utils';
import {Table} from 'primeng/table';
import {Router} from '@angular/router';
import {gql} from '../../../gql';
import {ErrorService} from '../../shared/services/error.service';
import {AdditionalRouteEvents} from 'src/app/app-route-reuse-strategy';


type RecordType = QueryResult<typeof USERS_QUERY>['users'][number];

const USERS_QUERY = gql(/* GraphQL */`
  query UsersOverviewComponent_users(
    $search: String
  ) {
    users(
      search: $search
    ) {
      id
      name
      email
      newcomerLocations {
        id
        name
      }
    }
  }
`);

const COLUMNS: TableColumn<RecordType>[] = [
  {
    field: 'name',
    key: 'name',
    name: 'Naam',
    width: '180px'
  },
  {
    field: 'email',
    key: 'email',
    name: 'Email',
  },
  {
    field: null,
    key: 'locations',
    name: 'Locatie',
  }
];

interface FilterParams {
  search: string | null;
}

@Component({
  selector: 'app-users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls: ['./users-overview.component.scss']
})
export class UsersOverviewComponent implements OnInit, OnDestroy, AdditionalRouteEvents {
  @ViewChild('table', {static: true}) table: Table | null = null;

  subscriptions = new Subscription();

  columns = COLUMNS;
  recordCount = 0;


  searchForm = new FormGroup<ModelToForm<FilterParams>>({
    search: new FormControl(null),
  });

  loadingRecords = false;
  loadingRecordCount = false;
  records$ = new BehaviorSubject<RecordType[]>([]);

  rangeFilterActive = false;
  areas: Area[] = [];
  users: RecordType[] = [];

  constructor(
    private apollo: Apollo,
    private router: Router,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.loadingRecordCount = true;
    this.loadingRecords = true;
    this.searchForm.valueChanges.pipe(
      startWith(this.searchForm.value),
      debounceTime(300),
      switchMap(filters => {
        this.loadingRecordCount = true;
        this.loadingRecords = true;

        return this.apollo.watchQuery({
          query: USERS_QUERY,
          variables: {
            search: filters.search
          }
        }).valueChanges.pipe(
          catchError(() => this.errorService.HandleGraphQLError('Ophalen van gebruikers')),
        );
      })
    ).subscribe(data => {
      this.users = data.data.users;
      this.loadingRecords = false;
      this.loadingRecordCount = false;
    });
  }

  assertUserType(user: RecordType): RecordType | null {
    return user;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  willEnter(): void {
    setTimeout(() => {
      notNull(this.table).scroller?.scrollTo({top: 1});
    });
  }

  navigate(u: Exclude<RecordType | null, false | 0 | "" | null | undefined>) {
    this.router.navigate(['user', u.id]);
  }
}
