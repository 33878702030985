import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    const next_location = localStorage.getItem('redirect_location');
    const next_location_redirect_time = localStorage.getItem('redirect_location_time')

    if (next_location === null || next_location_redirect_time === null) {
      this.router.navigate(['/']);
      return;
    }

    const currentTime = new Date().getTime() / 1000;

    if (currentTime < parseInt(next_location_redirect_time, 10) + 300) {
      this.router.navigateByUrl(next_location).then()
    } else {
      this.router.navigate(['/']).then(() => {
        localStorage.removeItem('redirect_location');
        localStorage.removeItem('redirect_location_time');
      })
    }
  }

}
