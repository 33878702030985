<div class="card">
    <h2><ng-container *ngIf="isNew">Nieuwe</ng-container> Gebruiker <ng-container *ngIf="!isNew">bewerken</ng-container></h2>


    <app-form-field [input]="formContainer.componentInput['email']"></app-form-field>
    <div class="mat-error" *ngIf="existingUserId">Er is reeds een user met dit mailadres. <a [routerLink]="[existingUserId]">Bekijk deze user</a></div>


    <div class="form-row">
        <div class="field w-100">
            <app-form-field [input]="formContainer.componentInput['firstName']"></app-form-field>
        </div>
        <div class="field w-100">
            <app-form-field [input]="formContainer.componentInput['lastName']"></app-form-field>
        </div>
    </div>

    <app-form-field [input]="formContainer.componentInput['newcomerLocations']"></app-form-field>

    <p-button class="save-button" label="Opslaan" icon="pi pi-save" [loading]="saveHelper.saving()" (click)="saveHelper.save()">
    </p-button>

    <p-button styleClass="p-button-warning" label="Reset 2FA" icon="pi pi-mobile" [loading]="savingOtp" (onClick)="resetOTP$.next(null)" *ngIf="otpActive">
    </p-button>
</div>
