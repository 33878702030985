import { Component, OnInit } from '@angular/core';
import {gql} from '../../../gql';
import {Apollo} from 'apollo-angular';
import {catchError, EMPTY, exhaustMap, filter, Subject, Subscription, switchMap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../shared/services/error.service';
import {QueryResult} from '../../shared/utils';
import {PlacementOfferResolution} from '../../../gql/graphql';
import {PLACEMENT_STATUS_OPTIONS} from '../newcomer-overview/newcomer-overview.component';
import {FormControl} from '@angular/forms';
import * as Sentry from '@sentry/angular';
import {ToastrService} from 'ngx-toastr';

const PLACEMENT_OFFER_QUERY =  gql(/* GraphQL */ `
  query PlacementOfferComponent_placementOfferQuery($id: ID!) {
    placementOffer(id: $id) {
      id
      newcomer {
        id
        firstName
        lastName
        placementStatus
      }
      location {
        ... on WegwijzerLocationType {
          id
          name
        }
      }
      capacity {
        id
        age
        description
      }
      date
      resolutionAt
      resolution
      resolutionBy
      failureReason
      finalized
      finalizedAt
    }
  }
`);

const PLACEMENT_OFFER_MUTATION = gql(/* GraphQL */ `
  mutation PlacementOfferComponent_placementOfferMutation($input: createOrUpdatePlacementOfferInput!) {
    createOrUpdatePlacementOffer(input: $input) {
      errors {
        field
      }
    }
  }
`);

const RIGHTS_QUERY = gql(/* GraphQL */ `
    query PlacementOfferComponent_rights {
      me {
        id
        managerHasAccessToSensitiveMessages
      }
    }

`);

type PlacementOfferType = QueryResult<typeof PLACEMENT_OFFER_QUERY>['placementOffer'];

type severityColors =  'success' | 'danger';

export const PLACEMENT_OFFER_OPTIONS: {
  [key in keyof typeof PlacementOfferResolution]: {
    value: (typeof PlacementOfferResolution & {all: null})[key] | null;
    name: string;
    color: severityColors | undefined;
  };
} = {
  ACCEPTED: {name: 'Geaccepteerd', value: PlacementOfferResolution.ACCEPTED, color: 'success'},
  REJECTED: {name: 'Geweigerd', value: PlacementOfferResolution.REJECTED, color: 'danger'},
  CANCELLED: {name: 'Geannuleerd', value: PlacementOfferResolution.CANCELLED, color: 'danger'},
  AWAITING_RESOLUTION: {name: 'In afwachting', value: PlacementOfferResolution.AWAITING_RESOLUTION, color: undefined},
};

@Component({
  selector: 'app-placement-offer',
  templateUrl: './placement-offer.component.html',
  styleUrls: ['./placement-offer.component.scss']
})
export class PlacementOfferComponent implements OnInit {
  subscriptions: Subscription[] = [];
  placementOfferId: string | null = null;
  records: PlacementOfferType[] = [];
  placementOfferOptions = PLACEMENT_OFFER_OPTIONS;
  PlacementOfferResolution = PlacementOfferResolution;
  placementOptionsArray = Object.values(PLACEMENT_STATUS_OPTIONS);
  newcomerStatusControl = new FormControl();
  submit$ = new Subject<PlacementOfferType>();
  cancelPlacementOffer = false;
  cancelMessage: FormControl = new FormControl();
  userHasRightsToSensitiveData = false;

  constructor(private apollo: Apollo,
              private route: ActivatedRoute,
              private errorService: ErrorService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit(): void {
    const placementOfferQuerySubscription = this.route.params.pipe(
      filter(params => params.hasOwnProperty('placementOfferId')),
      switchMap((params) => {
        this.placementOfferId = params['placementOfferId'] as string;
        return this.apollo
          .watchQuery({query: PLACEMENT_OFFER_QUERY, variables: {id: this.placementOfferId}, fetchPolicy: 'network-only'}).valueChanges
          .pipe(
            catchError(() => this.errorService.HandleGraphQLError('Ophalen van nieuwkomer'))
          );
      })
    ).subscribe(output => {
      if (output.data.placementOffer) {
        // p table always wants an array of records
        this.records = [{
          ...output.data.placementOffer
        }];

        this.newcomerStatusControl.setValue(output.data.placementOffer.newcomer.placementStatus);
      }
    });

    const placementOfferMutationSubscription = this.submit$.pipe(
      exhaustMap(offer => {
        if (!offer) {
          return EMPTY;
        }
        return this.apollo.mutate({
          mutation: PLACEMENT_OFFER_MUTATION,
          variables: {
            input: {
              id: offer.id,
              finalized: true,
              finalizedAt: new Date(),
              newcomerStatus: this.newcomerStatusControl.value,
              resolution: this.cancelPlacementOffer ? PlacementOfferResolution.CANCELLED : offer.resolution,
              failureReason: this.cancelPlacementOffer ? this.cancelMessage.value : offer.failureReason
            }
          },
          update: cache => {
            cache.evict({
              id: 'ROOT_QUERY',
              fieldName: 'placementOffers'
            });
          }
        }).pipe(
          catchError(() => this.errorService.HandleGraphQLError('Opslaan van plaatsingsvoorstel'))
        );
      }),
      catchError((error: Error) => {
        Sentry.captureException(error);
        this.errorService.displayError('Validatie probleem', 'Een of meer verplichte velden is niet ingevuld.');
        return EMPTY;
      })
    ).subscribe(output => {
        this.toastr.success('Plaatsingsvoorstel opgeslagen');
        this.router.navigate(['/plaatsing-voorstellen-overzicht']);
    });

    const rightsQuerySubscription = this.apollo.query({
      query: RIGHTS_QUERY
    }).pipe(
      catchError(() => this.errorService.HandleGraphQLError('Ophalen van rechten'))
    ).subscribe(output => {
      this.userHasRightsToSensitiveData = output.data.me.managerHasAccessToSensitiveMessages;
    });

    this.subscriptions.push(placementOfferQuerySubscription);
    this.subscriptions.push(placementOfferMutationSubscription);
    this.subscriptions.push(rightsQuerySubscription);
  }

  submitPlacementOffer(offer: PlacementOfferType): void {
    if (this.cancelPlacementOffer && !this.cancelMessage.value) {
      this.toastr.error('Annuleringsreden is verplicht');
      return;
    }

    this.submit$.next(offer);
  }

  cancel(): void {
    this.cancelPlacementOffer = true;

  }

  assertItemType(item: PlacementOfferType): PlacementOfferType {
    return item;
  }

}
