import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {gql} from '../../../../gql';
import {Apollo} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, Subscription, switchMap} from 'rxjs';
import {QueryResult, TableColumn, throwExpression} from '../../../shared/utils';
import {ErrorService} from '../../../shared/services/error.service';
import {Table} from 'primeng/table';

const NEARBY_LOCATIONS_QUERY = gql(/* GraphQL */`
  query NearbyLocationsComponent_nearbyLocations($id: ID!) {
    newcomer(id: $id) {
      id
      nearbyLocations(radius: 5000) {
        distance
        location {
          id
          name
          cityAreaName
          remainingCapacity
          totalCapacity
        }
      }
    }
  }
`);

type QueryRecordType = NonNullable<QueryResult<typeof NEARBY_LOCATIONS_QUERY>['newcomer']>['nearbyLocations'][0]['location'];
type DistanceType = Pick<NonNullable<QueryResult<typeof NEARBY_LOCATIONS_QUERY>['newcomer']>['nearbyLocations'][0], 'distance'>;
type RecordType = QueryRecordType & DistanceType & {displayCapacity: string};

const COLUMNS: (TableColumn<RecordType>)[] = [
  {
    field: 'displayCapacity',
    key: 'displayCapacity',
    name: 'Capaciteit',
    width: '110px',
  },
  {
    field: 'cityAreaName',
    key: 'cityArea',
    name: 'stadsdeel',
    width: '250px',
  },
  {
    field: 'name',
    key: 'name',
    name: 'Naam',
  },
  {
    field: 'distance',
    key: 'distance',
    name: 'Afstand',
  }
];

@Component({
  selector: 'app-nearby-locations',
  templateUrl: './nearby-locations.component.html',
  styleUrls: ['./nearby-locations.component.scss']
})
export class NearbyLocationsComponent implements OnInit, OnDestroy {
  @ViewChild('table', {static: true}) table: Table | null = null;

  columns = COLUMNS;
  loadingRecords = true;
  subscriptions: Subscription[] = [];
  constructor(private apollo: Apollo,
              private route: ActivatedRoute,
              private errorService: ErrorService,
              private router: Router) { }

  ngOnInit(): void {
    const table = this.table ?? throwExpression('Table did not initialize');

    const nearbyLocationsSubscription = this.route.params.pipe(
      filter(params => params.hasOwnProperty('newcomerId')),
      switchMap((params) => {
        return this.apollo.query({
          query: NEARBY_LOCATIONS_QUERY,
          variables: {id: params['newcomerId'] as string}
        }).pipe(catchError(() => this.errorService.HandleGraphQLError('Fout bij ophalen van locaties in de buurt.')));
      }),
    ).subscribe(output => {
      const records: RecordType[] = output.data.newcomer?.nearbyLocations.map(nearbyLocation => {
        return {
          ...nearbyLocation.location,
          distance: nearbyLocation.distance,
          displayCapacity: `${nearbyLocation.location.remainingCapacity}/${nearbyLocation.location.totalCapacity}`,
        };
      }) ?? [];

      table.value = records ?? [];
      this.loadingRecords = false;
    });

    this.subscriptions.push(nearbyLocationsSubscription);
  }

  assertRecordType(record: unknown): record is RecordType {
    if (typeof record === 'object' && record != null) {
      return !('loading' in record);
    }

    return false;
  }

  navigate(location: RecordType): void {
    this.router.navigate(['/', 'locatie', location.id]).then();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
