import {Component, OnDestroy, OnInit} from '@angular/core';
import {gql} from '../../../gql';
import {catchError, exhaustMap, Subject, Subscription} from "rxjs";
import fileDownload from 'js-file-download';
import {ErrorService} from "../../shared/services/error.service";
import {Apollo} from 'apollo-angular';

const GET_REPORTS = gql(/* GraphQL */`
    query ReportsComponent__get_reports {
        reports {
            id
            name
            __typename
        }
    }
`);

const GET_REPORT = gql(/* GraphQL */`
    query ReportsComponent__get_report($id: ID!) {
        report(id: $id) {
            id
            name
            downloadAsBase64
            __typename
        }
    }
`);

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
    downloadReport$ = new Subject<string>();
    subscriptions = new Subscription();
    reports: Array<{ __typename?: "Report"; id: string; name: string }> = [];

    constructor(
        private apollo: Apollo,
        private errorService: ErrorService
    ) {
    }

    ngOnInit() {
        this.subscriptions.add(
            this.downloadReport$.pipe(
                exhaustMap((reportId) => {
                    return this.apollo
                        .query({query: GET_REPORT, variables: {id: reportId}, fetchPolicy: "network-only"}).pipe(
                            catchError(() => this.errorService.HandleGraphQLError('Ophalen van rapportage'))
                        );
                })
            ).subscribe((result) => {
                const report = result.data.report;

                function _base64ToArrayBuffer(base64: any) {
                    const binary_string = window.atob(base64);
                    const len = binary_string.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binary_string.charCodeAt(i);
                    }
                    return bytes.buffer;
                }

                const arrayBuffer = _base64ToArrayBuffer(report.downloadAsBase64);
                fileDownload(arrayBuffer, report.name + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            })
        );


        this.apollo.client.watchQuery({
            query: GET_REPORTS
        }).subscribe(result => {
            this.reports = result.data.reports;
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
